import React, { useState, useEffect, useContext } from 'react';
import '../../CustomCss/customStyles.css';
import './popUp.css';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap styles
import '@fortawesome/fontawesome-free/css/all.min.css'; // FontAwesome icons
import '../../Assets/fonts/fontawesome/duotone.svg';
import 'swiper/css'; // Import base CSS
import 'swiper/css/navigation'; // Import navigation CSS if used
import 'swiper/css/pagination'; // Import pagination CSS if used
import '../../Components/LandingPageComp/popUp.css';
import Voucher_1 from '../../Assets/img/voucher/voucher_1.png';
import Voucher_2 from '../../Assets/img/voucher/voucher_2.png';
import Voucher_3 from '../../Assets/img/voucher/voucher_3.png';
import { AuthContext } from '../../Utils/Contexts/authContext';

import { useNavigate } from 'react-router-dom';

const TournamentPopUp = ({ onClose }) => {
    const [showPopup, setShowPopup] = useState(false);
    const { accessToken } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            if (accessToken || true) return;
            setShowPopup(true);
        }, 5000);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <>
            {showPopup && (
                <div
                    className="white-popup col-12"
                    style={{
                        position: 'fixed',
                        top: '10%',
                        left: '0%',
                        zIndex: '100',
                        background: 'transparent',
                    }}
                >
                    <div className="row justify-content-center gx-10 pt-10">
                        <div
                            className="col-8 bg-black3 align-self-center rounded-10"
                            style={{
                                padding: '20px',
                                boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
                            }}
                        >
                            <div>
                                <h2
                                    className="product-title"
                                    style={{
                                        fontWeight: '800',
                                        fontSize: '50px',
                                    }}
                                >
                                    Welcome to AgonGames{' '}
                                    <button
                                        title="Close (Esc)"
                                        type="button"
                                        className="text-white"
                                        onClick={() => {
                                            setShowPopup(false);
                                            onClose?.();
                                        }}
                                        style={{
                                            display: 'block',
                                            float: 'right',
                                            background: 'none',
                                            border: 'none',
                                        }}
                                    >
                                        ×
                                    </button>
                                </h2>
                                <p
                                    className="text"
                                    style={{
                                        fontWeight: '400',
                                        fontSize: '18px',
                                        marginLeft: '15%',
                                    }}
                                >
                                    Start earning for your in game skills. Rewards across 100+ popular brands
                                </p>
                            </div>

                            <div className="hiw-section tourn-hiw">
                                <div className="hiw-card">
                                    <div className="hiw-card-icon">
                                        <i className="fa fa-swords"></i>
                                    </div>
                                    <div className="hiw-content">
                                        <h3>Join a tournament</h3>
                                        <p>Start earning by winning tournaments.First 5 tournaments are free to join</p>
                                    </div>
                                </div>
                                <div className="hiw-card">
                                    <div className="hiw-card-icon">
                                        <i className="fa fa-shop"></i>
                                    </div>
                                    <div className="hiw-content">
                                        <h3>Gamer's Shop</h3>
                                        <p>Select rewards across 100+ popular brands</p>
                                    </div>
                                </div>
                            </div>

                            <div className="pop-slider lt-10">
                                <div
                                    style={{
                                        'margin-left': '14%',
                                        marginTop: '10px',
                                    }}
                                >
                                    <img className="pop-up-img" src={Voucher_1} alt="Voucher_1" />
                                    <img className="pop-up-img" src={Voucher_2} alt="Voucher_2" />
                                    <img className="pop-up-img" src={Voucher_3} alt="Voucher_3" />
                                </div>
                            </div>

                            <div
                                className="actions"
                                style={{
                                    marginBottom: '25px',
                                    marginLeft: '38%',
                                }}
                            >
                                <button
                                    className="th-btn"
                                    onClick={() =>
                                        navigate(`/login`, {
                                            state: {
                                                from: window.location.pathname,
                                            },
                                        })
                                    }
                                    style={{
                                        color: '#0B0E13',
                                        marginRight: '-2%',
                                    }}
                                >
                                    Log In
                                    <span className="icon">
                                        <i className="fa-solid fa-arrow-right ms-3"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TournamentPopUp;
