import React , {useEffect} from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap'; 
import { Link } from 'react-router-dom';

const BlogDetailsArea = () => {
  return (
    <>
    <section class="th-blog-wrapper blog-details space-top space-extra2-bottom" style={{backgroundColor:"black", marginTop:"100px"}}>
        <div class="container">
            <div class="row gx-40">
                <div class="col-xxl-8 col-lg-7">
                    <div class="th-blog blog-single">
                        <div class="blog-img">
                            <img src="assets/img/blog/blog-s-1-1.jpg" alt="Blog -mage"/>
                        </div>
                        <div class="blog-content">
                            <div class="blog-meta">
                                <a class="author" href="blog.html"><i class="fa-light fa-user"></i>By Jonson</a>
                                <a href="blog.html"><i class="fa-light fa-calendar"></i>30 Nov, 2024</a>
                                <a href="blog-details.html"><i class="fa-light fa-comment"></i>3 Comments</a>
                            </div>
                            <h2 class="blog-title">Strategies for Dominating Your Favorite Game</h2>
                            <p>Successful esports teams exhibit strong communication, strategic coordination, and individual player skills. Team chemistry, effective coaching, and adaptability to changing meats are also crucial factors. Professional esports players engage in rigorous training routines, including daily practice sessions, analysis of opponents' gameplay, and participation in scrims (practice matches).
                            </p>
                            <p>Many also focus on physical fitness and mental well-being maintain peak performance. building a high-performance gaming PC involves selecting the right components such as a powerful graphics card, fast processor, ample RAM.</p>

                            <blockquote>
                                <p>Dramatically develop market positioning expertise with long-term high-impact ROI. Authoritatively provide access to adaptive web-readiness.</p>
                                <cite>Michel Clarck</cite>
                            </blockquote>

                            <p class="mb-40">Ultimately, music and sound effects are a crucial aspect of game design that can make or break the player experience. By carefully selecting and creating the right sounds, designers can create immersive, engaging games that players.</p>

                            <h2 class="blog-title">Music And Sound Effects:</h2>
                            <p class="mb-30">In order to create effective music and sound effect for a game designer need to consider a few key factors. For example, the tone of the game, the intended emotions.</p>
                            <div class="checklist list-grid mb-35">
                                <ul>
                                    <li><i class="fas fa-check-circle"></i>Setting the Tone</li>
                                    <li><i class="fas fa-check-circle"></i>Building Immersion</li>
                                    <li><i class="fas fa-check-circle"></i>Enhancing Gameplay</li>
                                </ul>
                            </div>
                            <div class="row gx-40">
                                <div class="col-md-6">
                                    <div class="blog-img">
                                        <img class="w-100" src="assets/img/blog/blog_inner_1.jpg" alt="Blog -mage"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="blog-img">
                                        <img class="w-100" src="assets/img/blog/blog_inner_2.jpg" alt="Blog -mage"/>
                                    </div>
                                </div>
                            </div>
                            <p>The rise of mobile gaming has significantly expanded the gaming audience, reaching a broader demographic. It has also influenced game design trends, with many developers creating titles specifically tailored for mobile platforms.</p>
                            <div class="share-links clearfix ">
                                <div class="row justify-content-between">
                                    <div class="col-md-auto">
                                        <span class="share-links-title">Tags:</span>
                                        <div class="tagcloud">
                                            <a href="blog.html">E-Sports</a>
                                            <a href="blog.html">Tournaments</a>
                                        </div>
                                    </div>
                                    <div class="col-md-auto text-xl-end">
                                        <div class="th-social style3 align-items-center">
                                            <span class="share-links-title">Share:</span>
                                            <a class="facebook" href="https://www.facebook.com/"><span><i class="fab fa-facebook-f"></i></span></a>
                                            <a href="https://www.twitter.com/"><span><img src="assets/img/icon/x-twitter-icon.svg" alt="img"/></span></a>
                                            <a href="https://www.instagram.com/"><span><img src="assets/img/icon/instagram-icon.svg" alt="img"/></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="blog-author">
                        <div class="auhtor-img">
                            <img src="assets/img/blog/blog-author.jpg" alt="Blog-Author-Image"/>
                        </div>
                        <div class="media-body">
                            <div class="media">
                                <div class="media-left">
                                    <h3 class="author-name"><a href="team-details.html">Ronald Richards</a></h3>
                                    <span class="author-desig">Founder & CEO</span>
                                </div>
                                <div class="media-body">
                                    <div class="th-social style2 align-items-center">
                                        <a href="https://www.facebook.com/"><i class="fab fa-facebook-f"></i></a>
                                        <a href="https://www.twitter.com/"><i class="fab fa-twitter"></i></a>
                                        <a href="https://www.linkedin.com/"><i class="fab fa-linkedin-in"></i></a>
                                        <a href="https://www.instagram.com/"><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>

                            <p class="author-text">Unleash the full potential of your enterprise with our comprehensive range of consulting services, designed to align with your goals and foster innovation.</p>
                        </div>
                    </div>
                    <div class="th-comments-wrap ">
                        <h3 class="blog-inner-title"><i class="far fa-comments"></i> Comments (03)</h3>
                        <ul class="comment-list">
                            <li class="th-comment-item">
                                <div class="th-post-comment">
                                    <div class="comment-avater">
                                        <img src="assets/img/blog/comment-author-1.jpg" alt="Comment-Author"/>
                                    </div>
                                    <div class="comment-content">
                                        <h3 class="name">Adam Jhon</h3>
                                        <span class="commented-on">25Aug, 2024 08:56pm</span>
                                        <p class="text">Your health and well-being are our top priorities. We take the time to listen to your concerns, answer your questions.</p>
                                        <div class="reply_and_edit">
                                            <a href="blog-details.html" class="reply-btn"><i class="fas fa-reply"></i>Reply</a>
                                        </div>
                                    </div>
                                </div>
                                <ul class="children">
                                    <li class="th-comment-item">
                                        <div class="th-post-comment">
                                            <div class="comment-avater">
                                                <img src="assets/img/blog/comment-author-2.jpg" alt="Comment-Author"/>
                                            </div>
                                            <div class="comment-content">
                                                <h3 class="name">Jhon Abraham</h3>
                                                <span class="commented-on">25July, 2024 10:56pm</span>
                                                <p class="text">We understand that every patient is unique, and their healthcare needs may vary. That's why we create individualized.</p>
                                                <div class="reply_and_edit">
                                                    <a href="blog-details.html" class="reply-btn"><i class="fas fa-reply"></i>Reply</a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li class="th-comment-item">
                                <div class="th-post-comment">
                                    <div class="comment-avater">
                                        <img src="assets/img/blog/comment-author-3.jpg" alt="Comment-Author"/>
                                    </div>
                                    <div class="comment-content">
                                        <h3 class="name">Anadi Juila</h3>
                                        <span class="commented-on">15 Jan, 2024 08:56pm</span>
                                        <p class="text">Our clinic is strategically located for easy access, ensuring that you can reach us conveniently from various parts of the community.</p>
                                        <div class="reply_and_edit">
                                            <a href="blog-details.html" class="reply-btn"><i class="fas fa-reply"></i>Reply</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div> 
                    <div class="th-comment-form ">
                        <div class="form-title">
                            <h3 class="blog-inner-title mb-2"><i class="fa-solid fa-reply"></i> Leave a Comment</h3>
                            <p class="form-text">Your email address will not be published. Required fields are marked *</p>
                        </div>
                        <div class="row">
                            <div class="col-md-6 form-group style-border">
                                <input type="text" placeholder="Your Name*" class="form-control"/>
                                <i class="far fa-user"></i>
                            </div>
                            <div class="col-md-6 form-group style-border">
                                <input type="text" placeholder="Your Email*" class="form-control"/>
                                <i class="far fa-envelope"></i>
                            </div>
                            <div class="col-12 form-group style-border">
                                <input type="text" placeholder="Website" class="form-control"/>
                                <i class="far fa-globe"></i>
                            </div>
                            <div class="col-12 form-group style-border">
                                <textarea placeholder="Write a Comment*" class="form-control"></textarea>
                                <i class="far fa-pencil"></i>
                            </div>
                            <div class="col-12 form-group mb-0">
                                <button class="th-btn">SEND MESSAGE <i class="far fa-arrow-right ms-2"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-4 col-lg-5">
                    <aside class="sidebar-area">
                        <div class="widget widget_search  ">
                            <form class="search-form">
                                <input type="text" placeholder="Search here..."/>
                                <button type="submit"><i class="far fa-search"></i></button>
                            </form>
                        </div>
                        <div class="widget widget_categories  ">
                            <h3 class="widget_title">Categories</h3>
                            <ul>
                                <li>
                                    <a href="blog.html">LIVE GAME</a>
                                    <span>(2)</span>
                                </li>
                                <li>
                                    <a href="blog.html">FANTASY</a>
                                    <span>(2)</span>
                                </li>
                                <li>
                                    <a href="blog.html">GAMING</a>
                                    <span>(2)</span>
                                </li>
                                <li>
                                    <a href="blog.html">MX-XBOX</a>
                                    <span>(2)</span>
                                </li>
                                <li>
                                    <a href="blog.html">SHOOTING</a>
                                    <span>(2)</span>
                                </li>
                            </ul>
                        </div>
                        <div class="widget  ">
                            <h3 class="widget_title">Recent Posts</h3>
                            <div class="recent-post-wrap">
                                <div class="recent-post">
                                    <div class="media-img">
                                        <a href="blog-details.html"><img src="assets/img/blog/recent-post-1-1.jpg" alt="Blog -mage"/></a>
                                    </div>
                                    <div class="media-body">
                                        <h4 class="post-title"><a class="text-inherit" href="blog-details.html">A Day in the Life of an Esports Event</a></h4>
                                        <div class="recent-post-meta">
                                            <a href="blog.html"><i class="fa-light fa-calendar"></i>30 Nov, 2024</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="recent-post">
                                    <div class="media-img">
                                        <a href="blog-details.html"><img src="assets/img/blog/recent-post-1-2.jpg" alt="Blog -mage"/></a>
                                    </div>
                                    <div class="media-body">
                                        <h4 class="post-title"><a class="text-inherit" href="blog-details.html">Influential Figures in the History</a></h4>
                                        <div class="recent-post-meta">
                                            <a href="blog.html"><i class="fa-light fa-calendar"></i>05 Dec, 2024</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="recent-post">
                                    <div class="media-img">
                                        <a href="blog-details.html"><img src="assets/img/blog/recent-post-1-3.jpg" alt="Blog -mage"/></a>
                                    </div>
                                    <div class="media-body">
                                        <h4 class="post-title"><a class="text-inherit" href="blog-details.html">Behind the Scenes of Your Favorite</a></h4>
                                        <div class="recent-post-meta">
                                            <a href="blog.html"><i class="fa-light fa-calendar"></i>09 Sep, 2024</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="widget widget_tag_cloud  ">
                            <h3 class="widget_title">Popular Tags</h3>
                            <div class="tagcloud">
                                <a href="blog.html">GAME APP</a>
                                <a href="blog.html">E-SPORTS</a>
                                <a href="blog.html">TOURNAMENTS</a>
                                <a href="blog.html">MATCH</a>
                                <a href="blog.html">3D</a>
                                <a href="blog.html">GAME ANIMATION</a>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default BlogDetailsArea
