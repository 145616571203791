import React from 'react';
import '../../App.css';
import '../../CustomCss/customStyles.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap styles
import '@fortawesome/fontawesome-free/css/all.min.css'; // FontAwesome icons
import Feature2_2SVG from '../../Assets/img/icon/about_feature_2_2.svg';

const HowItWorks = () => {
    return (
        <>
            <div className="hiw-container">
                <div className="sub-title style2 hiw-heading">
                    <h2># HOW IT WORKS</h2>
                </div>
                <div className="hiw-cards-section">
                    <div className="hiw-card">
                        <div className="hiw-card-icon">
                            <i className="fa fa-gift"></i>
                        </div>
                        <div className="hiw-content">
                            <h3>Step 1</h3>
                            <p>
                                Pick your favourite game and add your in game
                                name
                            </p>
                        </div>
                    </div>
                    <div className="hiw-card">
                        <div className="hiw-card-icon">
                            <i className="fa fa-gift"></i>
                        </div>
                        <div className="hiw-content">
                            <h3>Step 2</h3>
                            <p>
                                Join the Tournament that fits your vibe. We’ve
                                got tons of options!
                            </p>
                        </div>
                    </div>
                    <div className="hiw-card">
                        <div className="hiw-card-icon">
                            <i className="fa fa-gift"></i>
                        </div>
                        <div className="hiw-content">
                            <h3>Step 3</h3>
                            <p>
                                Keep grinding, climb the Leaderboard, and win
                                Real Cash Rewards.
                            </p>
                        </div>
                    </div>
                    {/* <div className="hiw-card">
                        <div className="hiw-card-icon">
                            <i className="fa fa-gift"></i>
                        </div>
                        <div className="hiw-content">
                            <h3>Step 4</h3>
                            <p>
                                Connect with our hardcore gaming community and
                                repeat!!
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default HowItWorks;
