import React from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'jquery-ui/ui/widgets/slider'; // Example widget
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import ColorSwitcher from '../../Components/Common/ColorSwitcher';
import Footer2 from '../../Components/Common/Footer2';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import SimpleHeader2 from '../../Components/Common/SimpleHeader2';
import SideNavbar from '../../Components/UserProfilePageComp/SideNavbar/SideNavbar';
import Form from '../../Components/UserProfilePageComp/Form/ProfileForm';
import Rewards from '../../Components/UserProfilePageComp/Rewards/Rewards';
import Dashboard from '../../Components/UserProfilePageComp/Dashboard/Dashboard';
import DetailedDash from '../../Components/UserProfilePageComp/DetailedDash/DetailedDash';

const UserProfilePage = () => {
  return (
    <>
    <div style={{backgroundColor:"black"}}>
      <Cursor/>
      <Preloader/>
      <div className="main-content">        
          <SideNavbar/>
      </div>
      <Form/>
      <Rewards/>
      <Dashboard/>
      <DetailedDash/>
      <ScrollToTop/> 
    </div>
    </>
  )
}

export default UserProfilePage
