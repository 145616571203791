import React, { useState, useContext } from 'react';
import '../../App.css';
import '../../CustomCss/customStyles.css';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';
import { AuthContext } from '../../Utils/Contexts/authContext';
import Timer from '../Common/Timer';
import GameHandlePopup from '../Common/GameHandlePopup';

const TournamentCardMobile = ({
    gameId,
    tournamentId,
    tournamentImage,
    gameVSImg,
    subtitle1,
    title1,
    subtitle2, // Duration in hours
    title2, // Prize
    endTime, // Start time in "HH:MM" format
    startTime, // Start date in "MM/DD/YYYY" format
    status,
    detailsLink,
    currentPlayers,
    maxPlayers,
    entryFee,
    userRegistered,
    userRegistrationAllowed,
    ctaText,
    ctaEnabled,
    gamerHandle,
    duration,
    title,
    mode,
    progress,
    matchType,
    prize,
    rank,
    players,
    winRate,
    prizePoolType,
}) => {
    const [showPopup, setShowPopup] = useState({
        status: false,
        gameId: gameId,
    });
    const [ctaTextComp, setCTATextComp] = useState(ctaText);
    const [ctaEnabledcomp, setCTAEnabledComp] = useState(ctaEnabled);
    const [isLoading, setIsLoading] = useState(false);
    const [newGamerHandle, setNewGamerHandle] = useState('');
    const [showGamerHandlePrompt, setShowGamerHandlePrompt] = useState(false);
    const [isRegistered, setIsRegistered] = useState(userRegistered);
    const navigate = useNavigate();
    const { accessToken } = useContext(AuthContext);

    const handleCardClick = () => {
        navigate(detailsLink);
    };

    const closePopup = () => {
        setShowPopup({ status: false, gameId: gameId });
    };

    // Register for Tournament
    const registerForTournament = async () => {
        try {
            const response = await fetch(`${BASE_URL}/tournaments/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ tournament_id: tournamentId }),
            });

            const data = await response.json();
            if (data.code === 200) {
                alert('Successfully registered!');
                setIsRegistered(true);
                setShowGamerHandlePrompt(false); // Close modal on success
                setCTATextComp(data.cta_text);
                setCTAEnabledComp(data.cta_enabled);
            } else {
                alert(`Failed to register: ${data.message}`);
            }
        } catch (error) {
            console.error('Error registering for tournament:', error);
            setIsLoading(false);
            alert('Failed to register for the tournament.');
        }
    };

    // Handle "Entry" button click
    const handleEnterClick = async () => {
        if (!accessToken) {
            navigate(`/login`, { state: { from: window.location.pathname } });
        }

        if (isRegistered) {
            navigate(detailsLink, { state: { gamerHandle } });
            return;
        }

        // if (!userRegistrationAllowed || isLoading) return;

        // const existingGamerHandle = await fetchGamerHandle();
        if (gamerHandle) {
            await registerForTournament();
        } else {
            setShowPopup({ status: true, gameId: gameId });
            // setShowGamerHandlePrompt(true); // Show the modal if no gamer handle exists
        }
    };

    // Handle submitting new gamer handle
    const handleSubmitGamerHandle = async () => {
        if (!newGamerHandle) {
            alert('Please enter a valid gamer handle.');
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}/profile/add_gamer_handle`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    game_id: gameId,
                    gamer_handle: newGamerHandle,
                }),
            });

            const data = await response.json();
            if (data.code === 200) {
                alert(data.message);
                setShowGamerHandlePrompt(false);
                await registerForTournament();
            } else {
                alert(`Error: ${data.message}`);
            }
        } catch (error) {
            console.error('Error adding gamer handle:', error);
            alert('Failed to add gamer handle.');
        }
    };

    return (
        // <div className="col-lg-4">
        //   <aside className="sidebar-area gradient-border-wrapper next-match-section">
        <div
            className="widget-tournament-info gradient-border-wrapper next-match-card"
            onClick={handleCardClick}
            style={{ cursor: 'pointer', marginTop: '100px' }}
        >
            <div className="next-match-inner">
                {/* Live Status */}
                <div className="match-status">
                    <span className="status-label">{status}</span>
                </div>

                {/* Character Image */}
                <img src={tournamentImage} alt={`${title} Character`} className="character-image" />

                {/* Match Title */}
                <h3 className="match-title">{title1}</h3>

                {/* Match Details and Timer*/}
                <div className="match-details">
                    <div className="match-details-one">
                        {/* <div className="detail-box">
                  <span className="detail-label">Duration:{duration}</span>
                </div> */}
                        <div className="detail-box gradient-border">
                            {/* <span className="detail-label">
                                <i className="fa fa-clock"></i> DURATION: {subtitle2} HR
                            </span> */}
                            <span className="detail-label">DURATION: {subtitle2} HR</span>
                        </div>

                        <div className="detail-box gradient-border">
                            <span className="detail-label">{winRate}</span>
                        </div>
                    </div>
                    <Timer startTime={startTime} endTime={endTime}></Timer>
                    <div className="match-details-two">
                        <div className="detail-box gradient-border">
                            <span className="detail-label">{subtitle1}</span>
                        </div>
                        <div className="detail-box gradient-border">
                            <span className="detail-label">{prizePoolType}</span>
                        </div>
                    </div>
                </div>

                {/* Prize Info */}
                <div className="prize">
                    <span>Prize: {title2}/-</span>
                </div>

                {/* Rank and Players Info */}
                <div className="match-stats">
                    <span className="rank status-label">Rs.{entryFee}</span>
                    <span className="players status-label">
                        {currentPlayers}/{maxPlayers}
                    </span>
                </div>
            </div>
        </div>
        //   </aside>
        // </div>
    );
};

export default TournamentCardMobile;
