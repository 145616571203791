import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import '../../App.css';
// import '../../Assets/sass/scssImport.scss';
import '../../CustomCss/customStyles.css';
import $ from 'jquery';
// import Swiper from 'swiper';
// import Swiper from '../../Assets/js/swiper-bundle.min';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import FeatureCardBg from '../../Assets/img/feature/feature-card-bg.png';
import Feature1_1 from '../../Assets/img/feature/feature1-1.png';
import Feature1_2 from '../../Assets/img/feature/feature1-2.png';
import Feature1_3 from '../../Assets/img/feature/feature1-3.png';
import GameSec1Bg from '../../Assets/img/bg/game-sec1-bg.png';
import Card6 from '../Common/Card6';
import { BASE_URL, fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';

const GameArea2 = ({ callbackHandleClick }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [games, setGames] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGames = async () => {
            setIsLoading(true);
            try {
                const response = await fetchWithAuth(`${BASE_URL}/games`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });
                const data = await response.json();
                const duplicatedGames = [...data.games, ...data.games];
                setGames(duplicatedGames);
            } catch (e) {
                setError(e.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchGames();
    }, []);

    useEffect(() => {
        if (games.length > 0) {
            // Swiper.use([Navigation, Pagination, Autoplay]);
            new Swiper('.game-slider-1', {
                slidesPerView: 3,
                spaceBetween: 24,
                loop: true,
                speed: 1000,
                autoplay: { delay: 6000, disableOnInteraction: false },
                navigation: {
                    nextEl: '.game-slider-next',
                    prevEl: '.game-slider-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    // renderBullet: (index, className) => `<span class="${className}"></span>`,
                },
                breakpoints: {
                    576: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    992: { slidesPerView: 3 },
                    1200: { slidesPerView: 3 },
                },
            });
        }
    }, [games]);

    const handlePlayNowClick = (gameId) => {
        callbackHandleClick(gameId);
    };

    if (isLoading) return <div>Loading.....</div>;
    if (error) return <div>Something went wrong! Please try again later..</div>;

    const featureImages = [Feature1_1, Feature1_3, Feature1_2];
    const gameDescriptions = [
        'Successful esports teams exhibit strong communication, strategic.',
        'The rise of mobile gaming has significantly expanded the gaming.',
        'It has also influenced game design reaching a broader demographic.',
    ];

    return (
        // <section className="overflow-hidden">
        // <div className="container th-container1" style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
        //   <div className="game-sec-wrap1 space" data-bg-src={GameSec1Bg}>
        //     <div className="title-area text-center custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.1s">
        //       {/* <span className="sub-title"># Releases The Latest Game</span>
        //       <h2 className="sec-title">Game On, Power Up, Win Big <span className="text-theme">!</span></h2> */}
        //     </div>

        //   </div>
        // </div>
        // </section>
        <>
            <section
                className="feature-game-area-1  bg-black"
                style={{ backgroundColor: 'black', paddingBottom: '30px', marginTop: '200px' }}
            >
                <div className="container">
                    <div className="slider-area feature-game-slider1">
                        <div
                            className="swiper game-slider"
                            id="featureGameSlider1"
                            data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"3"}}}'
                        >
                            <div
                                className="title-area text-center custom-anim-top wow animated"
                                data-wow-duration="1.5s"
                                data-wow-delay="0.1s"
                            >
                                <span className="sub-title" style={{ marginTop: '30px', textAlign: 'center' }}>
                                    # Epic Clash
                                </span>
                                <h2 className="sec-title" style={{ textAlign: 'center', fontWeight: '700' }}>
                                    Game On, Level Up, Win Big <span className="text-theme">!</span>
                                </h2>
                                <div className="swiper-wrapper">
                                    {games.map((game, index) => (
                                        <div className="" key={game.game_id}>
                                            <Card6
                                                gameId={game.game_id}
                                                featureCardBg={FeatureCardBg}
                                                featureImage={featureImages[index % featureImages.length]}
                                                gameTitle={game.name}
                                                gameDescription={gameDescriptions[index % gameDescriptions.length]}
                                                gameDetailsLink={`/games`}
                                                playNowLink={`/games`}
                                                isAccountConnected={false}
                                                handlePlayNowClick={handlePlayNowClick}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* <div className="swiper-pagination slider-pagination"></div> */}
                        </div>
                        <button data-slider-prev="#featureGameSlider1" className="slider-arrow style3 slider-prev">
                            <i className="fas fa-angle-left"></i>
                        </button>
                        <button data-slider-next="#featureGameSlider1" className="slider-arrow style3 slider-next">
                            <i className="fas fa-angle-right"></i>
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default GameArea2;
