import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../Utils/Contexts/authContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import useScreenSize from '../../Utils/Contexts/useScreenSize';
import '../../App.css';
import '../../Components/LoginPageComp/LoginForm.css';
import 'jquery-ui/ui/widgets/slider'; // Example widget
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import HeroSection from '../../Components/LandingPageComp/heroSection';
import ProGameArea1 from '../../Components/ProtectedComponents/proGameArea1';
import AboutUs from '../../Components/LandingPageComp/aboutUs';
import JoinUs from '../../Components/LandingPageComp/joinUs';
import GamersShop from '../../Components/LandingPageComp/gamersShop';
import Footer1 from '../../Components/Common/Footer1';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import ProTournamentsList2 from '../../Components/ProtectedComponents/proTournamentsList2';
import FirstLoginPopUp from '../../Components/LandingPageComp/productLightBox';
import SimpleHeader3 from '../../Components/Common/SimpleHeader3';

import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';
import TournamentListMobile from '../../Components/MobileViewComp/tournamentListMobile';

const ProLandingPage = () => {
    const { accessToken, logout } = useContext(AuthContext);
    const navigate = useNavigate(); // To navigate to the login page if not logged in
    const location = useLocation();
    const isMobile = useScreenSize();

    const [gameHandles, setGameHandles] = useState({});
    // Extract success message from location state if available

    const [showSuccessMessage, setShowSuccessMessage] = useState(Boolean(location.state?.successMessage));

    const [isFirstTimeUser, setIsFirstTimeUser] = useState(true);

    const [showPopup, setShowPopup] = useState(false);

    // Triggered once on mount if a success message exists in location state
    useEffect(() => {
        if (location.state?.successMessage) {
            setShowSuccessMessage(true);

            // Clear the message after a delay
            setTimeout(() => {
                setShowSuccessMessage(false);

                // Clear the success message from location state to prevent repeat
                navigate(location.pathname, { replace: true });
            }, 3000);
        }
    }, [location.pathname, location.state, navigate]);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, []);

    useEffect(() => {
        setIsFirstTimeUser(localStorage.getItem('new_user') === 'true');
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowPopup(true);
        }, 5000); // 5 seconds delay

        // Clear the timer if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const fetchGamerHandle = async () => {
            try {
                const response = await fetch(`${BASE_URL}/profile/get_gamer_handle`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch gamer handle: ${response.statusText}`);
                }

                const data = await response.json();

                // Check if response is valid and set handles
                // console.log('downloaded handles', data.handles);
                if (data.handles && (Array.isArray(data.handles) || Object.keys(data.handles).length > 0)) {
                    const temp = {};
                    for (const key in data.handles) {
                        temp[data.handles[key]['game_id']] = data.handles[key]['gamer_handle'];
                    }
                    setGameHandles(temp);
                } else {
                    setGameHandles([]);
                }
            } catch (error) {
                console.error('Error fetching gamer handle:', error);
            }
        };

        fetchGamerHandle();
    }, [accessToken]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div>
            {accessToken ? (
                <>
                    <CSSTransition in={showSuccessMessage} timeout={500} classNames="slide" unmountOnExit>
                        <div className="success-notification show">{location.state?.successMessage}</div>
                    </CSSTransition>
                    <Cursor />
                    <Preloader />
                    {/* <ColorSwitcher/> */}
                    <ScrollToTop />
                    {/* <MainHeader2 toggleMenu={toggleMenu}/> */}
                    <SimpleHeader3 />

                    {/* <ProductLightBox/> */}
                    {/* <SideMenu1/> */}
                    <SideMenu2 isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
                    {/* <MobileMenu/> */}
                    <HeroSection />
                    {showPopup && isFirstTimeUser != undefined && isFirstTimeUser != null && isFirstTimeUser && (
                        <FirstLoginPopUp />
                    )}
                    {/* <Stats/> */}
                    <ProGameArea1 />
                    {/* <div id="how-it-works">
          <HowItWorks /> 
        </div> */}
                    {/* <Marquee/> */}

                    <div id="tournament">
                        {/* <ProTournamentsList1/> */}
                        {/* <ProTournamentsList2
                            selectedGameId={''}
                            gameHandles={gameHandles}
                            landing={true}
                            pageType={'private'}
                        /> */}
                        {isMobile ? (
                            <TournamentListMobile
                                selectedGameId={''}
                                gameHandles={gameHandles}
                                landing={true}
                                pageType={'private'}
                            />
                        ) : (
                            <ProTournamentsList2
                                selectedGameId={''}
                                gameHandles={gameHandles}
                                landing={true}
                                pageType={'private'}
                            />
                        )}
                    </div>
                    <AboutUs />
                    <JoinUs />
                    <GamersShop />
                    <Footer1 />
                </>
            ) : (
                <p>You are not logged in. Redirecting to login page...</p>
            )}
        </div>
    );
};

export default ProLandingPage;
