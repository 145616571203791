import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import '../../CustomCss/findGameAccount.css';
import '../../CustomCss/customStyles.css';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';

// const gameIdImageMap = {
//     '75c0c41e-5d98-4d21-8e85-9c96ed240da1': [
//         require('../../Assets/img/GameHandles/75c0c41e-5d98-4d21-8e85-9c96ed240da1_1.png'),
//         require('../../Assets/img/GameHandles/75c0c41e-5d98-4d21-8e85-9c96ed240da1_2.png'),
//         require('../../Assets/img/GameHandles/75c0c41e-5d98-4d21-8e85-9c96ed240da1_3.png'),
//     ],
//     'b9a85983-cb93-49ac-9200-cce1f69ef70f': [
//         require('../../Assets/img/GameHandles/b9a85983-cb93-49ac-9200-cce1f69ef70f_1.png'),
//         require('../../Assets/img/GameHandles/b9a85983-cb93-49ac-9200-cce1f69ef70f_2.png'),
//         require('../../Assets/img/GameHandles/b9a85983-cb93-49ac-9200-cce1f69ef70f_3.png'),
//     ],
//     'b75c9a8c-7b58-4acd-a0a5-d63b911fb272': [
//         require('../../Assets/img/GameHandles/b75c9a8c-7b58-4acd-a0a5-d63b911fb272_1.png'),
//     ],
// };

// const loadImages = async (gameId) => {
//     const images = [];
//     const imagePaths = [
//         `../../Assets/img/GameHandles/${gameId}_1.png`,
//         `../../Assets/img/GameHandles/${gameId}_2.png`,
//         `../../Assets/img/GameHandles/${gameId}_3.png`,
//     ];

//     for (const path of imagePaths) {
//         try {
//             console.log('Path : ', path);
//             const image = await import(`${path}`);
//             images.push(image.default);
//         } catch (error) {
//             console.error(`Image not found: ${path}`, error);
//         }
//     }

//     return images;
// };

const requireImage = require.context(
    '../../Assets/img/GameHandles',
    false,
    /\.(png|jpe?g|svg)$/,
);

const loadImages = (gameId) => {
    const images = [];
    const imagePaths = [
        `${gameId}_1.png`,
        `${gameId}_2.png`,
        `${gameId}_3.png`,
    ];

    for (const fileName of imagePaths) {
        try {
            const image = requireImage(`./${fileName}`);
            images.push(image.default || image);
        } catch (error) {
            console.error(`Image not found: ${fileName}`, error);
        }
    }
    return images;
};

function GameHandlePopup({ isOpen, onClose, gameId }) {
    const [newGamerHandle, setNewGamerHandle] = useState(''); // For storing new gamer handle input
    const [isLoading, setIsLoading] = useState(false);
    const [images, setImages] = useState([]);

    // useEffect(() => {
    //     loadImages(gameId).then((loadedImages) => setImages(loadedImages));
    // }, [gameId]);

    useEffect(() => {
        if (isOpen) {
            setImages(loadImages(gameId));
        }
    }, [isOpen, gameId]);

    if (!isOpen) return null;

    const handleSubmitGamerHandle = async () => {
        if (!newGamerHandle) {
            alert('Please enter a valid gamer handle.');
            return;
        }

        try {
            setIsLoading(true);
            const response = await fetch(
                `${BASE_URL}/profile/add_gamer_handle`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem(
                            'accessToken',
                        )}`, // Add your token logic here
                    },
                    body: JSON.stringify({
                        game_id: gameId,
                        gamer_handle: newGamerHandle,
                    }),
                },
            );

            const data = await response.json();
            if (data.code === 200) {
                alert('Gamer handle added successfully!');
                onClose();
                // setGamerHandle(newGamerHandle); // Update the gamer handle state
                // setAccountConnected(true); // Set account as connected
            } else {
                alert(`Error: ${data.message}`);
            }
        } catch (error) {
            console.error('Error adding gamer handle:', error);
            alert('Failed to add gamer handle.');
        } finally {
            setIsLoading(false);
        }
    };

    return ReactDOM.createPortal(
        <div className="modal-overlay" onClick={onClose}>
            <div
                className="modal-content form-group"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="gamer-handle-modal">
                    <h3>Enter Your Gamer Handle</h3>
                    <input
                        id="gamerHandle"
                        type="text"
                        value={newGamerHandle}
                        onChange={(e) => setNewGamerHandle(e.target.value)}
                        placeholder="Enter gamer handle"
                        className="modal-input"
                    />
                    <button
                        onClick={handleSubmitGamerHandle}
                        className="modal-submit-btn"
                    >
                        Add Handle
                    </button>
                </div>
                {/* <Link to="/find-gamer-id">How do I find Gamer Id?</Link> */}
                <div className="game-id-images-container">
                    <div className="game-id-images">
                        <h4>How do I find Gamer Id?</h4>
                        {/* {images.map((imageSrc, index) => (
                            <img
                                key={index}
                                src={imageSrc}
                                alt={`Gamer ID hint ${index + 1}`}
                                className="game-id-image"
                            />
                        ))} */}
                        <div>
                            {images.length > 0 ? (
                                images.map((src, index) => (
                                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                    <img
                                        key={index}
                                        src={src}
                                        className="game-id-image product-img"
                                        alt={`Game image ${index + 1}`}
                                    />
                                ))
                            ) : (
                                <p>No images available</p>
                            )}
                        </div>
                    </div>
                </div>
                <button className="modal-close" onClick={onClose}>
                    x
                </button>
            </div>
        </div>,
        document.body,
    );
}

export default GameHandlePopup;
