import React from 'react';
import '../../App.css';
import '../../CustomCss/customStyles.css';
import { useNavigate } from 'react-router-dom';
import Timer from '../Common/Timer';

const NextMatchCard = ({
  status,
  imageSrc,
  title ,
  duration,
  progress,
  matchType,
  startTime,
  endTime,
  mode,
  prize,
  rank,
  players,
  detailsLink
}) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(detailsLink);
  };


  return (
    // <div className="col-lg-4">
    //   <aside className="sidebar-area gradient-border-wrapper next-match-section">
        <div className="widget-tournament-info gradient-border-wrapper next-match-card" onClick={handleCardClick} style={{ cursor: 'pointer' }}>
          <div className="next-match-inner">
            {/* Live Status */}
            <div className="match-status">
              <span className="status-label">{status}</span>
            </div>

            {/* Character Image */}
            {imageSrc && <img src={imageSrc} alt={`${title} Character`} className="character-image"/>}

            {/* Match Title */}
            <h3 className="match-title">{title}</h3>

            {/* Match Details and Timer*/}
            <div className="match-details">
              <div className="match-details-one">
                {/* <div className="detail-box">
                  <span className="detail-label">Duration:{duration}</span>
                </div> */}
                <div className="detail-box gradient-border">
                  <span className="detail-label">
                    <i className="fa fa-clock"></i> {duration}
                  </span>
                </div>

                <div className="detail-box gradient-border">
                  <span className="detail-label">{progress}</span>
                </div>
              </div>
              <Timer startTime={startTime} endTime={endTime}></Timer>
              <div className="match-details-two">
                <div className="detail-box gradient-border">
                  <span className="detail-label">{mode}</span>
                </div>
                <div className="detail-box gradient-border">
                  <span className="detail-label">{matchType}</span>
                </div>
              </div>
            </div>

            {/* Prize Info */}
            <div className="prize">
              <span>Prize: {prize}/-</span>
            </div>

            {/* Rank and Players Info */}
            <div className="match-stats">
              <span className="rank status-label">Rank - {rank}</span>
              <span className="players status-label">Players - {players}</span>
            </div>
          </div>
        </div>
    //   </aside>
    // </div>
  );
}

export default NextMatchCard;

