import React , { useEffect, useState, useContext } from 'react';
import '../../Assets/sass/scssImport.scss'
import '../../App.css';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Utils/Contexts/authContext';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap'; 
import LogoSVG from '../../Assets/img/logo.jpg';
import JijiBg from '../../Assets/img/bg/jiji-bg.png';
import FooterBg from '../../Assets/img/bg/footer2-bg.png'

const Footer2 = () => {
    const { accessToken } = useContext(AuthContext); 
    const navigate = useNavigate(); 

    const handleRedirect = (e) => {
        e.preventDefault(); // Prevent default anchor behavior
        
        if (accessToken) {
            // Redirect to /home if the access token is available
            navigate('/home');
        } else {
            // Redirect to / if there's no access token
            navigate('/');
        }
    };

// useEffect(() => {
//     // Apply background images from data-bg-src attribute
//     if ($("[data-bg-src]").length > 0) {
//       $("[data-bg-src]").each(function () {
//         var src = $(this).attr("data-bg-src");
//         $(this).css("background-image", "url(" + src + ")");
//         $(this).removeAttr("data-bg-src").addClass("background-image");
//       });
//     }

//     // Apply background colors from data-bg-color attribute
//     if ($('[data-bg-color]').length > 0) {
//       $('[data-bg-color]').each(function () {
//         var color = $(this).attr('data-bg-color');
//         $(this).css('background-color', color);
//         $(this).removeAttr('data-bg-color');
//       });
//     }

//     // Apply border color from data-border attribute
//     $('[data-border]').each(function() {
//       var borderColor = $(this).data('border');
//       $(this).css('--th-border-color', borderColor);
//     });

//     // Apply mask images from data-mask-src attribute
//     if ($('[data-mask-src]').length > 0) {
//       $('[data-mask-src]').each(function () {
//         var mask = $(this).attr('data-mask-src');
//         $(this).css({
//           'mask-image': 'url(' + mask + ')',
//           '-webkit-mask-image': 'url(' + mask + ')'
//         });
//         $(this).addClass('bg-mask');
//         $(this).removeAttr('data-mask-src');
//       });
//     };

//   }, []); // Run once when the component mounts


  return (
    <>
      <footer className="footer-wrapper footer-layout1" data-bg-src={FooterBg}>
        <div className="container">
            <div className="footer-top text-center">
                {/* <div className="footer-logo bg-repeat" data-bg-src={JijiBg}>
                    <a href="#" onClick={handleRedirect}>
                        <span data-mask-src={LogoSVG} className="logo-mask"></span>
                        <img src={LogoSVG} alt="Bame"/>
                    </a>
                </div> */}
                {/* <div className="footer-links">
                    <ul>
                        <li><a href="index.html">HOME</a></li>
                        <li><a href="about.html">ABOUT US</a></li>
                        <li><a href="tournament.html">TOURNAMENT</a></li>
                        <li><a href="blog.html">BLOG</a></li>
                        <li><a href="gallery.html">GALLERY</a></li>
                        <li><a href="contact.html">CONTACT</a></li>
                    </ul>
                </div> */}
            </div>
        </div>
        <div className="container">
            <div className="widget-area">
                <div className="row justify-content-between">
                    <div className="col-md-6 col-lg-auto">
                        <div className="widget footer-widget">
                            <h3 className="widget_title">Office <span className="text-theme">Location:</span></h3>
                            <div className="th-widget-contact">
                                <div className="info-box">
                                    <p className="info-box_text">
                                    Vasanta Nagar, Bengaluru, 
                                    <br/> 560052
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-auto">
                        <div className="widget footer-widget">
                            <h3 className="widget_title">Contact <span className="text-theme">Information:</span></h3>
                            <div className="th-widget-contact">
                                <div className="info-box">
                                    <p className="info-box_text">
                                        <a className="info-box_link" href="tel:163254736587">+91 9933943456</a>
                                        <a className="info-box_link" href="mailto:contact@agongames.com">contact@agongames.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-auto">
                        <div className="widget footer-widget">
                            <h3 className="widget_title">Follow <span className="text-theme">With Us:</span></h3>
                            <div className="th-widget-contact">
                                <div className="header-links">
                                    <ul className="th-social style-mask">
                                        <li><a className="discord" href="https://discord.gg/Mn9mVSVz"><i className="fab fa-discord"></i></a></li>
                                        <li><a className="instagram" href="https://www.instagram.com/agongamescom/">
                                                <i className="fab fa-instagram"></i>
                                            </a></li>
                                        <li><a className="x-twitter" href="https://x.com/agongamescom"><i className="fab fa-x-twitter"></i></a></li>                                       
                                        <li><a className="youtube" href="https://www.youtube.com/@Agongamescom"><i className="fab fa-youtube"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row justify-content-center">
                <div className="col-lg-8">
                    <form className="newsletter-form">
                        <div className="form-group">
                            <input className="form-control" type="email" placeholder="Subscribe to Our Newsletter" required=""/>
                            <button type="submit" className="th-btn">DOWNLOAD NOW <i className="far fa-arrow-right ms-2"></i></button>
                        </div>
                    </form>
                </div>
            </div> */}
        </div>
        <div className="copyright-wrap text-center bg-repeat" data-bg-src={JijiBg}>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6">
                        <p className="copyright-text bg-repeat" data-bg-src={JijiBg}>
                            <i className="fal fa-copyright"></i> Copyright 2024 <a href="#" onClick={handleRedirect}>Agon Games</a> All Rights Reserved.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}

export default Footer2
