import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import '../../App.css';
// import '../../Assets/sass/scssImport.scss';
import { Link } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap';
import Swiper from 'swiper';
import 'swiper/css'; // Import base CSS
import 'swiper/css/navigation'; // Import navigation CSS if used
import 'swiper/css/pagination'; // Import pagination CSS if used
import FeatureCardBg from '../../Assets/img/feature/feature-card-bg.png';
import Feature1_1 from '../../Assets/img/feature/feature1-1.png';
import Feature1_2 from '../../Assets/img/feature/feature1-2.png';
import Feature1_3 from '../../Assets/img/feature/feature1-3.png';
import GameSec1Bg from '../../Assets/img/hero/hero-bg1-1.jpg';
import ProGameCard6 from '../ProtectedComponents/proGameCard6';
import { fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';
import GameHandlePopup from '../Common/GameHandlePopup';

const ProGameArea2 = ({ gameHandles, callbackHandleClick }) => {
    const [showPopup, setShowPopup] = useState({ status: false, gameId: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [games, setGames] = useState([]);
    const navigate = useNavigate();

    const closePopup = () => {
        setShowPopup(false);
    };

    useEffect(() => {
        const fetchGames = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${BASE_URL}/games`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                // Duplicate the games array - Can be removed when more new games are added
                // const duplicatedGames = [...data.games];
                setGames(data.games);
                // setGames(data.games || []); Uncomment this after removing duplicate games
            } catch (e) {
                setError(e.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchGames();
    }, []);

    useEffect(() => {
        if (games.length > 0) {
            new Swiper('.game-slider-1', {
                slidesPerView: 3,
                spaceBetween: 24,
                loop: true,
                speed: 1000,
                autoplay: { delay: 6000, disableOnInteraction: false },
                navigation: {
                    nextEl: '.game-slider-next',
                    prevEl: '.game-slider-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: (index, className) => `<span class="${className}"></span>`,
                },
                breakpoints: {
                    576: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    992: { slidesPerView: 3 },
                    1200: { slidesPerView: 3 },
                },
            });
        }
    }, [games]);

    const handlePlayNowClick = (gameId) => {
        console.log('gameid used', gameId);
        callbackHandleClick(gameId);
    };

    if (isLoading) {
        return <div>Loading.....</div>;
    }

    if (error) {
        return <div>Something went wrong! Please try again later..</div>;
    }

    // Variations for featureImage and gameDescription
    const featureImages = [Feature1_1, Feature1_3, Feature1_2];
    const gameDescriptions = [
        'Successful esports teams exhibit strong communication, strategic.',
        'The rise of mobile gaming has significantly expanded the gaming.',
        'It has also influenced game design reaching a broader demographic.',
    ];

    const handleConnectGameAccount = (connectGameId) => {
        // console.log(connectGameId);
        setShowPopup({ status: true, gameId: connectGameId });
    };

    return (
        <>
            {/* <section className="overflow-hidden" style={{ marginTop: '50px' }}> */}
            {/* <div
                    className="container th-container1"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <div className="game-sec-wrap1 space" data-bg-src={GameSec1Bg}> */}
            <div style={{ marginTop: '200px' }}>
                <div
                    className="title-area text-center custom-anim-top wow animated"
                    data-wow-duration="1.5s"
                    data-wow-delay="0.1s"
                >
                    <span className="sub-title"># Epic Clash</span>
                    <h2 className="sec-title">
                        Game On, Level Up, Win Big <span className="text-theme">!</span>
                    </h2>
                </div>
                <div className="game-area-container ">
                    <div className="game-slider-1 custom-game-card-container">
                        <div className="swiper-wrapper">
                            {games.map((game, index) => {
                                // Find the corresponding gamer handle for the current game

                                const gamerHandle = gameHandles[game.game_id];
                                return (
                                    <div
                                        // className="swiper-slide"
                                        key={game.game_id}
                                    >
                                        <ProGameCard6
                                            gameId={game.game_id}
                                            featureCardBg={FeatureCardBg}
                                            featureImage={featureImages[index % featureImages.length]}
                                            gameTitle={game.name}
                                            gameDescription={gameDescriptions[index % gameDescriptions.length]}
                                            gameDetailsLink={`/progames`}
                                            playNowLink={`/progames`}
                                            isAccountConnected={false}
                                            handlePlayNowClick={handlePlayNowClick}
                                            gameHandle={gamerHandle} // Pass gamer handle here
                                            handleConnectGameAccount={handleConnectGameAccount}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="swiper-pagination slider-pagination"></div>
                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
                <GameHandlePopup isOpen={showPopup.status} onClose={closePopup} gameId={showPopup.gameId} />
                {/* </section> */}
            </div>
        </>
    );
};

export default ProGameArea2;
