import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Utils/Contexts/authContext';
import useScreenSize from '../../Utils/Contexts/useScreenSize';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'jquery-ui/ui/widgets/slider'; // Example widget
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import ColorSwitcher from '../../Components/Common/ColorSwitcher';
import Footer2 from '../../Components/Common/Footer2';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import GamesList from '../../Components/GamePageComp/GamesList';
import GameArea from '../../Components/LandingPageComp/gameArea1';
import TournamentsVS from '../../Components/GamePageComp/tournamentsList2';
import SimpleHeader1 from '../../Components/Common/SimpleHeader1';
import MainHeader1 from '../../Components/Common/MainHeader1';
import GameArea2 from '../../Components/GamePageComp/gameArea2';
import TournamentsList2 from '../../Components/GamePageComp/tournamentsList2';
import ProTournamentsList2 from '../../Components/ProtectedComponents/proTournamentsList2';
import GamersShop from '../../Components/LandingPageComp/gamersShop';
import NewGamesArea from '../../Components/GamePageComp/newGamesArea';
import TournamentListMobile from '../../Components/MobileViewComp/tournamentListMobile';
import GameArea2Mobile from '../../Components/MobileViewComp/gameArea2Mobile';

const GamesPage = () => {
    const { accessToken } = useContext(AuthContext);
    const isMobile = useScreenSize();
    const location = useLocation(); // Get the location object
    const navigate = useNavigate();
    // Access the state passed via navigate
    const message = location.state?.message;

    const defaultGameId = '75c0c41e-5d98-4d21-8e85-9c96ed240da1';

    console.log('Game Id from click is', location.state?.gameId);

    const [selectedGameId, setSelectedGameId] = useState(location.state?.gameId || defaultGameId);

    // Handler for when a game is clicked
    const handleGameClick = (gameId) => {
        setSelectedGameId(gameId); // Update the selected game ID
        // $('html, body').animate({ scrollTop: $('#tournament').offset().top }, 1000);
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        if (accessToken) {
            // If token exists, navigate to /home
            navigate('/progames');
        }
        // If no token, stay on /gamespage (no need to do anything)
    }, []); // Add navigate as a dependency for useEffect

    return (
        <>
            {message && <div className="alert alert-success">{message}</div>}
            <Cursor />
            <Preloader />
            {/* <SimpleHeader1 toggleMenu={toggleMenu}/> */}
            <MainHeader1 toggleMenu={toggleMenu} />
            <SideMenu2 isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
            <ColorSwitcher />
            <div id="games">
                {/* <GameArea2 callbackHandleClick={handleGameClick} /> */}
                {isMobile ? (
                    <GameArea2Mobile callbackHandleClick={handleGameClick} />
                ) : (
                    <GameArea2 callbackHandleClick={handleGameClick} />
                )}
            </div>
            <div id="tournament">
                {/* <TournamentsList1/> */}
                {isMobile ? (
                    <TournamentListMobile
                        selectedGameId={selectedGameId}
                        gameHandles={{}}
                        landing={false}
                        pageType={'public'}
                    />
                ) : (
                    <ProTournamentsList2
                        selectedGameId={selectedGameId}
                        gameHandles={{}}
                        landing={false}
                        pageType={'public'}
                    />
                )}
            </div>
            <Footer2 />
            <ScrollToTop />
        </>
    );
};

export default GamesPage;
