// import React, { useEffect, useState, useContext } from 'react';
// import './Rewards.css'; // Import the CSS file
// import { AuthContext } from '../../../Utils/Contexts/authContext';
// import { fetchWithAuth } from '../../../Utils/ApiServices/apiFunctions';
// import { useNavigate } from 'react-router-dom'; // For navigation
// import { BASE_URL } from '../../../Utils/ApiServices/apiFunctions';

// const Rewards = () => {
//   const [rewards, setRewards] = useState([]); // Store rewards data
//   const [loading, setLoading] = useState(true); // Track loading state
//   const [error, setError] = useState(null); // Track error state
//   const { accessToken } = useContext(AuthContext); // Access token from AuthContext
//   const navigate = useNavigate(); // Navigation hook

//   // Fetch rewards from the API
//   useEffect(() => {
//     const fetchRewards = async () => {
//       try {
//         const response = await fetch(`${BASE_URL}/user/rewards`, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${accessToken}`,
//           },
//         });

//         const data = await response.json();
//         console.log("User Rewards data from the backend:", data);

//         if (data.code === 200) {
//           setRewards(data.user_rewards); // Update rewards state
//         } else {
//           throw new Error('Failed to fetch rewards');
//         }
//       } catch (err) {
//         setError(err.message); // Set error message if API call fails
//       } finally {
//         setLoading(false); // Stop loading once request completes
//       }
//     };

//     fetchRewards();
//   }, [accessToken]);

//   // Handle claim button click
//   const handleClaim = (reward) => {
//     // Redirect to /progames if not claimed
//     if (!reward.claimed) {
//       navigate('/progames'); // Redirect to the /progames page
//     }
//   };

//   // Display loading or error message if necessary
//   if (loading) return <div>Loading rewards...</div>;
//   if (error) return <div>Error: {error}</div>;

//   return (
//     <div style={{ backgroundColor: "black", marginTop: "200px", marginBottom:"100px" }}>
//       <div className="rewards-container">
//         <div className="header">
//           <h1>Rewards</h1>
//           <button className="redeem-button">Redeem XOPoints</button>
//         </div>
//         <div className="tabs">
//           <div className="tab active">Rewards</div>
//           <div className="tab">Claimed Rewards</div>
//         </div>
//         <div className="rewards-content">
//           <h2 className="rewards-title">
//             <span className="highlight">Game On, Power Up, Win Big!</span>
//           </h2>
//           <div className="rewards-list">
//             {rewards.map((reward) => (
//               <div key={reward.criteria} className="reward-item">
//                 <div className="reward-name">{reward.criteria}</div>

//                 {/* Render streak dots */}
//                 <div className="login-streak">
//                   {Array.from({ length: reward.streak }).map((_, i) => (
//                     <span key={`active-${i}`} className="streak-dot active"></span> // Active dots
//                   ))}
//                   {Array.from({ length: reward.max_streak - reward.streak }).map((_, i) => (
//                     <span key={`inactive-${i}`} className="streak-dot inactive"></span> // Inactive dots
//                   ))}
//                 </div>

//                 {/* Render bonus points */}
//                 <div className="points">
//                   {reward.total_bonus > 0 ? (
//                     <span className="points-badge">
//                       <i className="fa fa-coins"></i> {reward.total_bonus}
//                     </span>
//                   ) : (
//                     <div className="empty-dots">
//                       <span className="streak-dot"></span>
//                     </div>
//                   )}
//                 </div>

//                 {/* Claim button */}
//                 <button
//                   className="claim-button"
//                   disabled={reward.claimed} // Disable button if already claimed
//                   onClick={() => handleClaim(reward)}
//                 >
//                   {reward.claimed ? 'Claimed' : 'Claim'}
//                 </button>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Rewards;




import React, { useEffect, useState, useContext } from 'react';
import './Rewards.css'; // Import the CSS file
import { AuthContext } from '../../../Utils/Contexts/authContext';
import { useNavigate } from 'react-router-dom'; // For navigation
import { BASE_URL } from '../../../Utils/ApiServices/apiFunctions';

const Rewards = () => {
  const [rewards, setRewards] = useState([]); // Store rewards data
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error state
  const { accessToken } = useContext(AuthContext); // Access token from AuthContext
  const navigate = useNavigate(); // Navigation hook

  // Fetch rewards from the API
  useEffect(() => {
    const fetchRewards = async () => {
      try {
        const response = await fetch(`${BASE_URL}/user/rewards`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const data = await response.json();
        console.log("User Rewards data from the backend:", data);

        if (data.code === 200) {
          setRewards(data.user_rewards); // Update rewards state
        } else {
          throw new Error('Failed to fetch rewards');
        }
      } catch (err) {
        setError(err.message); // Set error message if API call fails
      } finally {
        setLoading(false); // Stop loading once request completes
      }
    };

    fetchRewards();
  }, [accessToken]);

  // Handle claim button click
  const handleClaim = (reward) => {
    // Redirection logic based on criteria
    if (!reward.claimed) {
      switch (reward.criteria) {
        case 'Join Tournaments':
        case 'Add in game name':
          navigate('/progames');
          break;
        case 'Update your profile':
          navigate('/profile-form');
          break;
        case 'Invite a friend':
          navigate('/my-dashboard');
          break;
        case 'Login Daily':
          navigate('/login');
          break;
        default:
          navigate('/my-rewards');
          break;
      }
    }
  };

  // Display loading or error message if necessary
  if (loading) return <div>Loading rewards...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={{ backgroundColor: "black", marginTop: "200px", marginBottom:"100px" }}>
      <div className="rewards-container">
        {/* <div className="header">
          <h1>Rewards</h1>
          <button className="redeem-button">Redeem XOPoints</button>
        </div> */}
        <div className="tabs">
          <div className="tab active">Rewards</div>
          {/* <div className="tab">Claimed Rewards</div> */}
        </div>
        <div className="rewards-content">
          <h2 className="rewards-title">
            <span className="highlight">Game On, Power Up, Win Big!</span>
          </h2>
          <div className="rewards-list">
            {rewards.map((reward) => (
              <div key={reward.criteria} className="reward-item">
                <div className="reward-name">{reward.criteria}</div>

                {/* Render streak dots */}
                <div className="login-streak">
                  {Array.from({ length: reward.streak }).map((_, i) => (
                    <span key={`active-${i}`} className="streak-dot active"></span> // Active dots
                  ))}
                  {Array.from({ length: reward.max_streak - reward.streak }).map((_, i) => (
                    <span key={`inactive-${i}`} className="streak-dot inactive"></span> // Inactive dots
                  ))}
                </div>

                {/* Render bonus points */}
                <div className="points">
                  {reward.total_bonus > 0 ? (
                    <span className="points-badge">
                      <i className="fa fa-coins"></i> {reward.total_bonus}
                    </span>
                  ) : (
                    <div className="empty-dots">
                      <span className="streak-dot"></span>
                    </div>
                  )}
                </div>

                {/* Claim button */}
                <button
                  className="claim-button"
                  disabled={reward.claimed} // Disable button if already claimed
                  onClick={() => handleClaim(reward)}
                >
                  {reward.claimed ? 'Claimed' : 'Claim'}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rewards;

