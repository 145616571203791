import React, { useEffect, useState, useContext, useRef } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../../CustomCss/customStyles.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import BameLogo from '../../Assets/img/logo.jpg';
import MobileMenu from '../LandingPageComp/mobileMenu';
import { AuthContext } from '../../Utils/Contexts/authContext';
import SideNavbar from '../UserProfilePageComp/SideNavbar/SideNavbar';
import BonusCash from '../../Assets/img/wallet/bonus_cash.png';
import RealCash from '../../Assets/img/wallet/real_cash.png';
import { useNavigate } from 'react-router-dom';

import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';

const MainHeader2 = ({ toggleMenu }) => {
    const navigate = useNavigate();
    const { accessToken } = useContext(AuthContext); // Authentication token
    const [isSticky, setIsSticky] = useState(false);
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State for mobile menu
    const [isSideNavbarOpen, setIsSideNavbarOpen] = useState(false); // State for SideNavbar visibility
    const [walletData, setWalletData] = useState({ bonus_wallet: 0, deposit_wallet: 0, winnings_wallet: 0 }); // Wallet data
    const [isLoading, setIsLoading] = useState(true); // State to show loading status
    const [error, setError] = useState(null); // State to hold any error
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('India');
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    // Close dropdown when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCountryChange = (country) => {
        setSelectedCountry(country); // Update the selected country
        setIsDropdownOpen(false); // Close the dropdown
    };

    useEffect(() => {
        const fetchWalletData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/user/profile`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) throw new Error('Failed to fetch wallet data');

                const data = await response.json();
                console.log('User Wallet data from the backend : ', data.user_data);
                setWalletData({
                    bonus_wallet: data.user_data.bonus_wallet,
                    deposit_wallet: data.user_data.deposit_wallet,
                    winnings_wallet: data.user_data.winnings_wallet,
                });
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching wallet data:', error);
                setError(error.message);
                setIsLoading(false);
            }
        };

        fetchWalletData();
    }, [accessToken]);

    const scrollToSection = (event) => {
        event.preventDefault(); // Prevent default anchor behavior
        const section = document.getElementById('how-it-works');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 500) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Toggle category menu visibility
    const toggleCategoryMenu = (e) => {
        e.preventDefault();
        setIsCategoryOpen(!isCategoryOpen);
    };

    // Toggle mobile menu visibility
    const toggleMobileMenu = () => {
        console.log('Button is clicked');
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const toggleSideNavbar = () => {
        setIsSideNavbarOpen(!isSideNavbarOpen);
    };

    const togglePartnerWithUs = () => {
        navigate('/partner-with-us');
    };

    return (
        <>
            <header className={`th-header header-layout1 ${isSticky ? 'sticky' : ''}`}>
                {/* Header Top */}
                <div className="header-top">
                    <div className="container">
                        <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
                            <div className="col-auto d-none d-lg-block">
                                <p className="header-notice"></p>
                                <div className="header-links">
                                    <ul>
                                        <li>
                                            <div className="header-notice">
                                                Hello, <span style={{ color: '#45F882' }}>Champions! </span>Games On!
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dropdown-link" ref={dropdownRef}>
                                                <a
                                                    className="dropdown-toggle"
                                                    href="#"
                                                    role="button"
                                                    id="dropdownMenuLink1"
                                                    onClick={toggleDropdown}
                                                    aria-expanded={isDropdownOpen}
                                                >
                                                    <i className="fa-solid fa-globe"></i> {selectedCountry}
                                                </a>
                                                {isDropdownOpen && (
                                                    <ul
                                                        className="dropdown-menu show"
                                                        style={{ textAlign: 'left', width: '250px' }}
                                                        aria-labelledby="dropdownMenuLink1"
                                                    >
                                                        {[
                                                            'Brazil',
                                                            'United States',
                                                            'South Korea',
                                                            'Philippines',
                                                            'Vietnam',
                                                            'Indonesia',
                                                            'United Kingdom',
                                                            'United Arab Emirates (UAE)',
                                                            'Saudi Arabia',
                                                        ].map((country) => (
                                                            <li key={country}>
                                                                <a
                                                                    className="dropdown-item"
                                                                    href="#"
                                                                    onClick={() => handleCountryChange(country)}
                                                                >
                                                                    {country}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="header-links">
                                    <ul className="th-social style-mask">
                                        <li>
                                            <a className="discord" href="https://discord.gg/Mn9mVSVz">
                                                <i className="fab fa-discord"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="instagram" href="https://www.instagram.com/agongamescom/">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="x-twitter" href="https://x.com/agongamescom">
                                                <i className="fab fa-x-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="youtube" href="https://www.youtube.com/@Agongamescom">
                                                <i className="fab fa-youtube"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Main Menu Area */}
                <div className={`sticky-wrapper ${isSticky ? 'sticky' : ''}`}>
                    <div className="menu-area">
                        <div className="container">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto">
                                    <div className="header-logo">
                                        <Link to="/home">
                                            <span data-mask-src={BameLogo} className="logo-mask"></span>
                                            <img src={BameLogo} alt="Bame" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <nav
                                        className="main-menu d-none d-lg-inline-block"
                                        style={{ marginLeft: '0px', color: 'white' }}
                                    >
                                        <ul>
                                            <li>
                                                <Link to="/home">HOME</Link>
                                            </li>
                                            <li>
                                                <Link to="/progames">TOURNAMENTS</Link>
                                            </li>
                                            <li>
                                                <Link to="/proShop">SHOP</Link>
                                            </li>
                                            <li>
                                                <Link to="/proleaderboard">LEADERBOARD</Link>
                                            </li>
                                            <li>
                                                <Link to="/my-rewards">MY REWARDS</Link>
                                            </li>
                                            {/* <li><Link to="/my-tournaments">MY TOURNAMENTS</Link></li> */}
                                            <li>
                                                <a href="#" onClick={togglePartnerWithUs}>
                                                    PARTNER WITH US
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" onClick={toggleSideNavbar}>
                                                    <i className="fa-solid fa-user"></i>
                                                </a>
                                            </li>

                                            {/* Wallet UI */}
                                            <li className="wallet-ui">
                                                {isLoading ? (
                                                    <span>Loading...</span>
                                                ) : (
                                                    <Link to="/my-wallet">
                                                        <div className="wallet-info">
                                                            {/* <span className="wallet-item">
                                      <i className="fa-solid fa-wallet"></i> ₹{walletData.deposit_wallet}
                                    </span> */}
                                                            <span className="wallet-item">
                                                                <img
                                                                    style={{ height: 18 }}
                                                                    src={BonusCash}
                                                                    alt="bonus"
                                                                />{' '}
                                                                {walletData.bonus_wallet}
                                                            </span>
                                                            <span className="wallet-item">
                                                                <img
                                                                    style={{
                                                                        height: 17,
                                                                        paddingLeft: 5,
                                                                        paddingBottom: 3,
                                                                    }}
                                                                    src={RealCash}
                                                                    alt="real"
                                                                />{' '}
                                                                {walletData.winnings_wallet}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                )}
                                            </li>
                                        </ul>
                                    </nav>

                                    <div className="header-button d-flex d-lg-none">
                                        <button className="th-menu-toggle" onClick={toggleMobileMenu}>
                                            <i className="far fa-bars"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className={`category-menu ${isCategoryOpen ? 'open-category' : 'close-category'}`}>
                            Your category menu content here
                        </div> */}
                        <div className="logo-bg"></div>
                    </div>
                </div>
            </header>

            {/* Mobile Menu */}
            <MobileMenu isOpen={isMobileMenuOpen} toggleMenu={toggleMobileMenu} />

            {/* SideNavbar */}
            {isSideNavbarOpen && <SideNavbar />}
        </>
    );
};

export default MainHeader2;
