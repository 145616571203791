import React , { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'jquery-ui/ui/widgets/slider'; // Example widget
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import ColorSwitcher from '../../Components/Common/ColorSwitcher';
import Footer2 from '../../Components/Common/Footer2';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import GamesList from '../../Components/GamePageComp/GamesList';
import GameArea from '../../Components/LandingPageComp/gameArea1';
import TournamentsVS from '../../Components/GamePageComp/tournamentsList2';
import SimpleHeader1 from '../../Components/Common/SimpleHeader1';
import ProGameArea2 from '../../Components/ProtectedComponents/proGameArea2';
import MainHeader2 from '../../Components/Common/MainHeader2';
import ProTournamentsList2 from '../../Components/ProtectedComponents/proTournamentsList2';
import SimpleHeader3 from '../../Components/Common/SimpleHeader3';
import UserTournamentsList from '../../Components/ProtectedComponents/userTournamentsList';
import ProGameArea3 from '../../Components/ProtectedComponents/proGameArea3';

import { AuthContext } from '../../Utils/Contexts/authContext';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';


const ProGamesPage = () => {
  const location = useLocation(); // Get the location object
  const { accessToken } = useContext(AuthContext);
  // Access the state passed via navigate
  const message = location.state?.message;
  const [gameHandles, setGameHandles] = useState({});
  // Add state to store selected game ID, starting with a default ID
  const defaultGameId = '75c0c41e-5d98-4d21-8e85-9c96ed240da1'; // Replace with actual default ID if needed

  const [selectedGameId, setSelectedGameId] = useState(
    location.state?.gameId || defaultGameId
  );

  // Fetch Gamer Handle
  useEffect(() => {
    const fetchGamerHandle = async () => {
      try {
        const response = await fetch(`${BASE_URL}/profile/get_gamer_handle`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch gamer handle: ${response.statusText}`);
        }

        const data = await response.json();

        // Check if response is valid and set handles
        // console.log('downloaded handles', data.handles);
        if (data.handles && (Array.isArray(data.handles) || Object.keys(data.handles).length > 0)) {
          const temp = {};
          for (const key in data.handles) {
            temp[data.handles[key]['game_id']] = data.handles[key]['gamer_handle'];
          }
          setGameHandles(temp); 

        } else {
          setGameHandles([]);
        }
      } catch (error) {
        console.error('Error fetching gamer handle:', error);
      }
    };

    fetchGamerHandle();
  }, [accessToken]);

  // Handler for when a game is clicked
  // const handleGameClick = (gameId) => {
  //   setSelectedGameId(gameId); // Update the selected game ID
  // };
  // Handler for when a game is clicked
  const handleGameClick = (gameId) => {
    setSelectedGameId(gameId); // Update the selected game ID
  };

  return (
    <>
      {message && <div className="alert alert-success">{message}</div>}
      <Cursor/>
      <Preloader/>
      <SimpleHeader3/>
      <SideMenu2/>
      <ColorSwitcher/>
      <div id='user-games'>
        {/* <GamesList/> */}
        {/* <ProGameArea3 callbackHandleClick={handleGameClick}/> */}
        <ProGameArea2 callbackHandleClick={handleGameClick} gameHandles={gameHandles}/>
      </div>
      <div id='user-tournament'>
        <UserTournamentsList selectedGameId={selectedGameId}/>
      </div>     
      <Footer2/>
      <ScrollToTop/>
    </>
  )
}

export default ProGamesPage
