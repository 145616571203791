import React, { useState } from 'react';
import '../../../CustomCss/customStyles.css';
import '../../LandingPageComp/popUp.css';
import '../../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap styles
import '@fortawesome/fontawesome-free/css/all.min.css'; // FontAwesome icons
import PartnerForm from './partnerForm';
import './partnerCheckList.css';
import Cursor from '../../Common/Cursor';
import Preloader from '../../Common/Preloader';
import Footer2 from '../../Common/Footer2';
import ScrollToTop from '../../Common/ScrollToTop';
import MainHeader1 from '../../Common/MainHeader1';
import SideMenu2 from '../../Common/SideMenu2';

const PartnerWithUs2 = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const submitData = () => {
        // Get form data from PartnerForm component
        const formElements = document.getElementsByClassName('pinput');
        const phoneInput = formElements[1].value;
        const nameInput = formElements[0].value;

        // Get selected partnership types
        const checkedItems = document.querySelectorAll('.checklist input[type="checkbox"]:checked');
        const partnershipTypes = Array.from(checkedItems)
            .map((checkbox) => {
                return checkbox.parentElement.textContent.trim();
            })
            .join(', ');

        // Get "other" text if applicable
        const otherText = document.querySelector('.partner-ta')?.value;
        const finalPartnershipType = otherText ? `${partnershipTypes}, Other: ${otherText}` : partnershipTypes;

        const formData = {
            partnership_type: finalPartnershipType,
            phone: phoneInput,
            email: `${nameInput.toLowerCase().replace(/\s/g, '')}@example.com`, // Creating email from name as it's not in form
        };

        // Make API call
        fetch('http://46.28.44.116:5001/api/partner', {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
                const result = window.confirm('Thank you for your interest! We will contact you soon.');
                if (result || !result) {
                    window.location.href = '/';
                }
                // Reset form
                document
                    .querySelectorAll('.checklist input[type="checkbox"]')
                    .forEach((checkbox) => (checkbox.checked = false));
                formElements[0].value = '';
                formElements[1].value = '';
                if (document.querySelector('.partner-ta')) {
                    document.querySelector('.partner-ta').value = '';
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                alert('Something went wrong. Please try again later.');
            });
    };

    return (
        <>
            <Cursor />
            <Preloader />
            <MainHeader1 toggleMenu={toggleMenu} />
            <SideMenu2 isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />

            {
                <div
                    className="partner-container"
                    style={{
                        border: '2px solid #ccc',
                        borderRadius: '15px',
                        padding: '30px',
                        marginTop: '200px',
                        marginBottom: '100px',
                        maxWidth: '800px',
                        backgroundColor: '#0B0E13',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        margin: '200px auto 0', // Added auto margins for horizontal centering
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <h4 className="text-white">Partner with us!</h4>
                        <p className="text-white">You can partner us in many ways</p>
                    </div>
                    <div className="partner-form">
                        <PartnerForm />
                    </div>
                    <div
                        className="partner-submit"
                        style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px', marginLeft: 'auto' }}
                    >
                        <button
                            data-filter=".cat1"
                            className="tab-btn th-btn style-border3 active"
                            type="button"
                            onClick={() => submitData()}
                        >
                            <span className="btn-border">Submit</span>
                        </button>
                    </div>
                </div>
            }
            <Footer2 />
            <ScrollToTop />
        </>
    );
};

export default PartnerWithUs2;
