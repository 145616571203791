import React, { useState, useEffect } from 'react';
import '../../CustomCss/customStyles.css'; // Import CSS for styling
import { useRazorpay } from 'react-razorpay';

import { BASE_URL, fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';

const PremiumAccount = () => {
    const [selectedPlan, setSelectedPlan] = useState('Monthly'); // State for selected plan
    const [isPurchased, setIsPurchased] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Add loading state
    const [activePlanDetails, setActivePlanDetails] = useState(null); // Add plan details state
    const { Razorpay } = useRazorpay();

    // Modified useEffect to fetch active plan
    useEffect(() => {
        const checkActivePlan = async () => {
            try {
                const response = await fetchWithAuth(`${BASE_URL}/active-plan`);
                if (response.ok) {
                    const data = await response.json();
                    if (data.plan.plan_duration) {
                        setIsPurchased(true);
                        setActivePlanDetails(data.plan);
                    }
                }
            } catch (error) {
                console.error('Failed to fetch active plan:', error);
            } finally {
                setIsLoading(false);
            }
        };

        checkActivePlan();
    }, []);

    // Add pricing configuration
    const planPricing = {
        Monthly: 499,
        'Half Yearly': 2499,
        Yearly: 4999,
    };

    // Modified createOrder function using fetch
    const createOrder = async () => {
        try {
            const response = await fetchWithAuth(`${BASE_URL}/create_order`, {
                method: 'POST',
                headers: {},
                body: JSON.stringify({
                    amount: planPricing[selectedPlan] * 100, // Convert to paise
                }),
            });

            if (!response.ok) {
                throw new Error('Order creation failed');
            }

            return response.json();
        } catch (error) {
            console.error('Order creation failed:', error);
            throw error;
        }
    };

    const handlePlanChange = (plan) => {
        setSelectedPlan(plan);
    };

    // Modified handlePayment function with fetch
    const handlePayment = async () => {
        try {
            const orderData = await createOrder();
            if (!orderData) {
                throw new Error('Order creation failed');
            }
            console.log(orderData);
            const orderId = orderData.order.id;
            console.log(orderId);
            const options = {
                key: 'rzp_test_SALkEXhm0sjxvq', //process.env.REACT_APP_RAZORPAY_KEY_ID,
                amount: planPricing[selectedPlan] * 100,
                currency: 'INR',
                name: 'Agon Gaming',
                description: `${selectedPlan} Pro Subscription`,
                order_id: orderId,
                handler: async (response) => {
                    try {
                        const verifyResponse = await fetchWithAuth(
                            `${BASE_URL}/payment_success`,
                            {
                                method: 'POST',
                                headers: {},
                                body: JSON.stringify({
                                    razorpay_payment_id:
                                        response.razorpay_payment_id,
                                    razorpay_order_id: orderId,
                                    plan: selectedPlan,
                                }),
                            },
                        );

                        if (!verifyResponse.ok) {
                            throw new Error('Payment verification failed');
                        }

                        // Save to localStorage and update state
                        localStorage.setItem('premiumStatus', 'true');
                        localStorage.setItem('selectedPlan', selectedPlan);
                        setIsPurchased(true);
                        alert('Payment Successful!');
                    } catch (error) {
                        console.error('Payment verification failed:', error);
                        alert('Payment verification failed!');
                    }
                },
                prefill: {
                    name: 'John Doe', // You should get this from user context
                    email: 'john.doe@example.com',
                    contact: '9999999999',
                },
                theme: {
                    color: '#F37254',
                },
            };

            const razorpayInstance = new Razorpay(options);
            razorpayInstance.open();
        } catch (error) {
            console.error('Payment initiation failed:', error);
            alert('Unable to initiate payment. Please try again.');
        }
    };

    return (
        <div className="premium-account-container">
            <h2 className="upgrade-title">Upgrade To Pro</h2>
            {isLoading ? (
                <div className="loader">Loading...</div> // Add your loader component here
            ) : isPurchased ? (
                <div className="purchased-status">
                    <h3>You are a Pro Member! 🎉</h3>
                    <p>Current Plan: {activePlanDetails?.plan_duration}</p>
                    <p>Expires on: {activePlanDetails?.expires_at}</p>
                </div>
            ) : (
                <>
                    {/* Plan selection tabs */}
                    <div className="plan-tabs">
                        {['Monthly', 'Half Yearly', 'Yearly'].map((plan) => (
                            <button
                                key={plan}
                                className={`plan-tab ${
                                    selectedPlan === plan ? 'active' : ''
                                }`}
                                onClick={() => handlePlanChange(plan)}
                            >
                                {plan}
                            </button>
                        ))}
                    </div>

                    {/* Display dummy content based on the selected plan */}
                    <div className="plan-content">
                        {selectedPlan === 'Monthly' && (
                            <p>
                                Monthly Plan: Enjoy access to the standard
                                features on a monthly basis.
                            </p>
                        )}
                        {selectedPlan === 'Half Yearly' && (
                            <p>
                                Half Yearly Plan: Get a discount when
                                subscribing for 6 months.
                            </p>
                        )}
                        {selectedPlan === 'Yearly' && (
                            <p>
                                Yearly Plan: Best value for annual subscribers
                                with extra perks.
                            </p>
                        )}
                    </div>

                    {/* Comparison table */}
                    <div className="comparison-table">
                        <div className="comparison-row header">
                            <div className="comparison-cell">
                                Standard Account
                            </div>
                            <div className="comparison-cell">
                                Agon Game Pass
                            </div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">
                                Limited to 5 Free Tournaments Daily
                            </div>
                            <div className="comparison-cell">
                                {selectedPlan === 'Monthly' &&
                                    'Unlimited Free Tournament Access for 1 Month'}
                                {selectedPlan === 'Half Yearly' &&
                                    'Unlimited Free Tournament Access for 6 Months'}
                                {selectedPlan === 'Yearly' &&
                                    'Unlimited Free Tournament Access for 1 Year'}
                            </div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">
                                20% of Rewards in Gems, 80% as Bonus Cash
                            </div>
                            <div className="comparison-cell">
                                100% of Rewards in Gems (for Prize Pools &
                                Leaderboards)
                            </div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">
                                Coupon Redemption Limited to 2 Brands (Google
                                Play, Flipkart)
                            </div>
                            <div className="comparison-cell">
                                {selectedPlan === 'Monthly' &&
                                    'Coupon Redemption from 50+ Popular Brands'}
                                {selectedPlan === 'Half Yearly' &&
                                    'Coupon Redemption from 75+ Popular Brands'}
                                {selectedPlan === 'Yearly' &&
                                    'Coupon Redemption from 100+ Popular Brands'}
                            </div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">Basic Support</div>
                            <div className="comparison-cell">
                                {selectedPlan === 'Monthly' &&
                                    'Priority Support (Monthly)'}
                                {selectedPlan === 'Half Yearly' &&
                                    'Priority Support (Half Yearly)'}
                                {selectedPlan === 'Yearly' &&
                                    '24/7 Priority Support (Yearly)'}
                            </div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">-</div>
                            <div className="comparison-cell">
                                {selectedPlan === 'Monthly' &&
                                    'Access to Weekly Mega Tournaments (Monthly)'}
                                {selectedPlan === 'Half Yearly' &&
                                    'Access to Weekly Mega Tournaments (Half Yearly)'}
                                {selectedPlan === 'Yearly' &&
                                    'Access to Weekly Mega Tournaments (Yearly)'}
                            </div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">-</div>
                            <div className="comparison-cell">
                                {selectedPlan === 'Monthly' &&
                                    'Access to Monthly Giveaways'}
                                {selectedPlan === 'Half Yearly' &&
                                    'Access to Monthly & Biannual Giveaways'}
                                {selectedPlan === 'Yearly' &&
                                    'Access to Monthly & Yearly Giveaways'}
                            </div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">-</div>
                            <div className="comparison-cell">
                                Premium Highlights on Leaderboards
                            </div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">-</div>
                            <div className="comparison-cell">
                                Player Analytics & Insights
                            </div>
                        </div>
                        <div className="comparison-row">
                            <div className="comparison-cell">-</div>
                            <div className="comparison-cell">
                                {selectedPlan === 'Monthly' &&
                                    'Early Access to New Features (Monthly)'}
                                {selectedPlan === 'Half Yearly' &&
                                    'Early Access to New Features (Half Yearly)'}
                                {selectedPlan === 'Yearly' &&
                                    'Early Access to New Features (Yearly)'}
                            </div>
                        </div>
                    </div>

                    {/* Upgrade button */}
                    <div className="upgrade-button-container">
                        <button
                            className="upgrade-button"
                            onClick={handlePayment}
                        >
                            Upgrade to Pro ${planPricing[selectedPlan]}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default PremiumAccount;
