import React from 'react';

const GameCard6Mobile = ({ gameId, gameTitle, gameImage, handlePlayNowClick, featureImage }) => {
    return (
        <div
            className="game-card-mobile"
            style={{
                backgroundColor: 'black',
                borderRadius: '10px',
                overflow: 'hidden',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer',
                textAlign: 'center',
            }}
            onClick={() => handlePlayNowClick(gameId)}
        >
            <div className="game-image-container" style={{ position: 'relative', overflow: 'hidden' }}>
                <img
                    src={featureImage}
                    alt={gameTitle}
                    style={{
                        width: '100%',
                        height: '120px', // Smaller height for mobile layout
                        objectFit: 'cover',
                    }}
                />
            </div>
            <div className="game-details" style={{ padding: '10px', textAlign: 'center' }}>
                <h3
                    style={{
                        fontSize: '14px',
                        fontWeight: '700',
                        color: '#fff',
                        margin: 0,
                    }}
                >
                    {gameTitle}
                </h3>
            </div>
        </div>
    );
};

export default GameCard6Mobile;
