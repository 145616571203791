import React , {useEffect} from 'react';
import '../../App.css';
import '../../CustomCss/customStyles.css'
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap'; 
import JijiBg from '../../Assets/img/bg/jiji-bg2.png'

const FAQ = () => {

    useEffect(() => {
        // Apply background images from data-bg-src attribute
        if ($("[data-bg-src]").length > 0) {
          $("[data-bg-src]").each(function () {
            var src = $(this).attr("data-bg-src");
            $(this).css("background-image", "url(" + src + ")");
            $(this).removeAttr("data-bg-src").addClass("background-image");
          });
        }
    
        // Apply background colors from data-bg-color attribute
        if ($('[data-bg-color]').length > 0) {
          $('[data-bg-color]').each(function () {
            var color = $(this).attr('data-bg-color');
            $(this).css('background-color', color);
            $(this).removeAttr('data-bg-color');
          });
        }
    
        // Apply border color from data-border attribute
        $('[data-border]').each(function() {
          var borderColor = $(this).data('border');
          $(this).css('--th-border-color', borderColor);
        });
    
        // Apply mask images from data-mask-src attribute
        if ($('[data-mask-src]').length > 0) {
          $('[data-mask-src]').each(function () {
            var mask = $(this).attr('data-mask-src');
            $(this).css({
              'mask-image': 'url(' + mask + ')',
              '-webkit-mask-image': 'url(' + mask + ')'
            });
            $(this).addClass('bg-mask');
            $(this).removeAttr('data-mask-src');
          });
        };
    
      }, []); // Run once when the component mounts

  return (
    <>
      <div className="bg-repeat overflow-hidden space"  style={{marginTop:"145px" , backgroundColor:"var(--th-body-background)" , textAlign:"center"}}>
        <div className="container-fluid p-0">
            <div className="contact-wrap-1">
                <div className="column justify-content-center align-items-center faq-page-container">
                    <div className="col-xxl-5 col-xl-6 faq-section">
                        <div className="title-area custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                            <span className="sub-title style2"># Common Questions About Our Company</span>
                            <h2 className="sec-title text-white" style={{fontSize:"65px" , fontWeight:"900"}}>Frequently Asked Questions <br/>About Our Esports & Gaming Site <span className="text-theme">!</span></h2>
                        </div>
                        <div className="accordion custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s" id="faqAccordion">


                            <div className="accordion-card">
                                <div className="accordion-header" id="collapse-item-1">
                                    <button className="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">What is AgonGames?</button>
                                </div>
                                <div id="collapse-1" className="accordion-collapse collapse show" aria-labelledby="collapse-item-1" data-bs-parent="#faqAccordion">
                                    <div className="accordion-body">
                                        <p className="faq-text">Platform for gamers like you to compete in tournaments across your favourite games, earn real cash rewards and connect with passionate gaming community</p>
                                    </div>
                                </div>
                            </div>


                            <div className="accordion-card">
                                <div className="accordion-header" id="collapse-item-2">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">How do I earn rewards?</button>
                                </div>
                                <div id="collapse-2" className="accordion-collapse collapse " aria-labelledby="collapse-item-2" data-bs-parent="#faqAccordion">
                                    <div className="accordion-body">
                                        <p className="faq-text">Play to earn: Join a tournament, top the leaderboard, and win rewards—it’s that simple!</p>
                                    </div>
                                </div>
                            </div>


                            <div className="accordion-card">
                                <div className="accordion-header" id="collapse-item-3">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">Why should I connect my game account?</button>
                                </div>
                                <div id="collapse-3" className="accordion-collapse collapse " aria-labelledby="collapse-item-3" data-bs-parent="#faqAccordion">
                                    <div className="accordion-body">
                                        <p className="faq-text">Your gamer account helps us track your progress automatically, so you can focus on playing, winning, and earning—no need to submit scores!
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-card">
                                <div className="accordion-header" id="collapse-item-4">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">What games are supported?</button>
                                </div>
                                <div id="collapse-4" className="accordion-collapse collapse " aria-labelledby="collapse-item-4" data-bs-parent="#faqAccordion">
                                    <div className="accordion-body">
                                        <p className="faq-text">We've got all the popular ones: Clash of Clans, Clash Royale, Chess.com. Got a game suggestion? Hit us up at <a>contact@agongames.com!</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-card">
                                <div className="accordion-header" id="collapse-item-5">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-5" aria-expanded="false" aria-controls="collapse-5">How do I contact you?</button>
                                </div>
                                <div id="collapse-5" className="accordion-collapse collapse " aria-labelledby="collapse-item-5" data-bs-parent="#faqAccordion">
                                    <div className="accordion-body">
                                        <p className="faq-text">We're all ears! Got suggestions, feedback, complaints, or want to work with us? Just drop us an email at <a>contact@agongames.com</a>, and we'll get back to you in no time!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-5 col-xl-6" data-bg-src={JijiBg} style={{marginTop:"50px" , paddingTop:"25px"}}>
                        <div className="title-area custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                            <span className="sub-title style2"># Hire Experience Gamer</span>
                            <h2 className="sec-title text-white">Contact Us & Meet Our Team <span className="text-theme">!</span></h2>
                        </div>
                        <form action="mail.php" method="POST" className="contact-form ajax-contact pb-xl-0 space-bottom custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                            <div className="row">
                                <div className="form-group style-border2 col-md-6">
                                    <input type="text" className="form-control" name="name" id="name" placeholder="Your Name"/>
                                    <i className="fal fa-user"></i>
                                </div>
                                <div className="form-group style-border2 col-md-6">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Email Address"/>
                                    <i className="fal fa-envelope"></i>
                                </div>
                                <div className="form-group style-border2 col-md-6">
                                    <input type="text" className="form-control" name="number" id="number" placeholder="Phone Number"/>
                                    <i className="fal fa-phone"></i>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group style-border2">
                                        <select className="form-select" aria-label="Default select example">
                                            <option selected>Subject</option>
                                            <option value="1">Esports</option>
                                            <option value="2">Tournament</option>
                                            <option value="3">Sports</option>
                                        </select>
                                        <i className="fal fa-angle-down"></i>
                                    </div>
                                </div>
                                <div className="col-12 form-group style-border2">
                                    <textarea placeholder="Write Massage...." className="form-control"></textarea>
                                    <i className="far fa-pencil"></i>
                                </div>
                                <div className="form-btn col-12">
                                    <button className="th-btn">Send Message <i className="fa-solid fa-arrow-right ms-2"></i></button>
                                </div>
                            </div>
                            <p className="form-messages mb-0 mt-3"></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default FAQ
