import React , { useState , useContext , useCallback , useEffect } from 'react';
import { Link, useParams , useNavigate , useLocation } from 'react-router-dom';
import { AuthContext } from '../../Utils/Contexts/authContext';
import {fetchWithAuth} from '../../Utils/ApiServices/apiFunctions';
import SimpleHeader3 from '../Common/SimpleHeader3';
import '../../CustomCss/customStyles.css';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';
const ConnectGameAccount = () => {
  const { gameId } = useParams();
  const [gamerHandle, setGamerHandle] = useState(''); // State to capture the gamer handle input
  const [existingHandle, setExistingHandle] = useState(null);
  const { accessToken } = useContext(AuthContext);
  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation(); // Get location to access state
  const redirectPath = location.state?.redirectPath || `/progames/${gameId}`; 

  console.log("Redirect Path :", redirectPath , location);

  console.log("GameId :" , gameId)


  const handleGamerHandleChange = useCallback((e) => {
    setGamerHandle(e.target.value);
  }, []);

  // useEffect(() => {
  //   console.log("Retrieved gameId from URL params in ConnectGameAccount:", gameId); // Check gameId from useParams
  // }, [gameId]);

   // Function to check if the gamer handle already exists for this game
   const checkExistingHandle = useCallback(async () => {
    console.log('checking existing handle')
  }, []);



  const handleSubmit = async () => {

    if (existingHandle) {
      alert(`Gamer handle already exists for this game: ${existingHandle}`);
      return;
    }

    const payload = {
      game_id: gameId, // This is the tournament/game ID from the route
      gamer_handle: gamerHandle, // This is the gamer handle input by the user
    };

    console.log("This is the payload" , payload);

    try {
      const response = await fetch(`${BASE_URL}/profile/add_gamer_handle`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`, 
        },
        body: JSON.stringify(payload), // Send the payload in the request body
      });

      const result = await response.json(); // Parse the JSON response from the backend

      if (response.ok) {
        console.log('Gamer handle successfully added:', result);
        localStorage.setItem(`gamerHandle_${gameId}`, gamerHandle);
        navigate(redirectPath , { state: { message: 'Gamer handle added successfully!' } });
      } 
      
      else {
        console.error('Failed to add gamer handle:', result);
        alert(`Failed to add gamer handle: ${result.message || 'An error occurred.'}`);
        // Handle error here, like showing an error message
      }
    } catch (error) {
      console.error('Error during API request:', error);
      alert('Error during API request. Please try again later.');
      // Handle general network errors
    }

   
  };

  return (
    <>
    <SimpleHeader3/>
      <div className="connect-game-account-card">
      <h2 className="connect-game-account-title">Connect your Game Account</h2>
      {existingHandle ? (
        <p>
          Gamer handle already exists for this game: <strong>{existingHandle}</strong>
        </p>
      ) : (
        <>
          <input
          className='connect-game-account-input'
            type="text"
            placeholder="Add your Gamer handle"
            value={gamerHandle}
            onChange={handleGamerHandleChange}
          />
          <button className="submit-btn" onClick={handleSubmit} disabled={!gamerHandle}>
            Submit
          </button>
        </>
      )}
      <Link to="/find-gamer-id">How do I find Gamer Id?</Link>
    </div>
    </>
  );
};

export default ConnectGameAccount;







