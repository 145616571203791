import React , { useEffect, useState , useRef } from 'react';
import { useLocation } from 'react-router-dom';
import '../../App.css';
import { Link } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap'; 

const ScrollToTop = () => {

  const [isVisible, setIsVisible] = useState(false);
  const progressPathRef = useRef(null);
  const { pathname } = useLocation(); 

  // Function to update the progress circle's stroke offset based on scroll position
  const updateProgress = () => {
    if (progressPathRef.current) {
      const progressPath = progressPathRef.current;
      const pathLength = progressPath.getTotalLength();
      const scroll = window.scrollY;
      const height = document.documentElement.scrollHeight - window.innerHeight;
      const progress = pathLength - (scroll * pathLength) / height;
      progressPath.style.strokeDashoffset = progress;
    }
  };

  // Function to handle scroll-to-top button visibility and click behavior
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
    updateProgress();
  };

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the path changes
  }, [pathname]);

  useEffect(() => {
    // Ensure the progressPath is available
    if (progressPathRef.current) {
      const progressPath = progressPathRef.current;
      const pathLength = progressPath.getTotalLength();

      // Initial settings for the SVG path
      progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
      progressPath.style.strokeDasharray = `${pathLength} ${pathLength}`;
      progressPath.style.strokeDashoffset = pathLength;
      progressPath.getBoundingClientRect();
      progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';

      // Initial progress update
      updateProgress();
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);
    
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
    <>
      {isVisible && (
        <div className="scroll-top show" onClick={scrollToTop}>        
          <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path
              ref={progressPathRef}
              d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
              style={{
                transition: 'stroke-dashoffset 10ms linear 0s',
                strokeDasharray: '307.919, 307.919',
                strokeDashoffset: '307.919',
              }}
            />
          </svg>
          <i className="fas fa-arrow-up custom-scroll-to-top"></i>
        </div>
      )}
    </>
  )
}

export default ScrollToTop
