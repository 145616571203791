import React , {useEffect} from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap'; 
import { Link } from 'react-router-dom';
import Blogs1_1 from '../../Assets/img/blog/blog-s-1-1.jpg';
import Blogs1_2 from '../../Assets/img/blog/blog-s-1-2.jpg';
import Blogs1_3 from '../../Assets/img/blog/blog-s-1-3.jpg';
import Blogs1_4 from '../../Assets/img/blog/blog-s-1-4.jpg';
import RecentPost1_1 from '../../Assets/img/blog/recent-post-1-1.jpg';
import RecentPost1_2 from '../../Assets/img/blog/recent-post-1-2.jpg';
import RecentPost1_3 from '../../Assets/img/blog/recent-post-1-3.jpg';


const BlogArea = () => {
  return (
    <>
      <section className="th-blog-wrapper space-top space-extra2-bottom" style={{backgroundColor:"black" ,marginTop:"100px"}}>
        <div className="container">
            <div className="row gx-40">
                <div className="col-xxl-8 col-lg-7">
                    <div className="th-blog blog-single has-post-thumbnail">
                        <div className="blog-img">
                            <a href="blog-details.html"><img src={Blogs1_1} alt="Blog-Image"/></a>
                        </div>
                        <div className="blog-content">
                            <div className="blog-meta">
                                <a className="author" href="blog.html"><i className="fa-light fa-user"></i>By Jonson</a>
                                <a href="blog.html"><i className="fa-light fa-calendar"></i>21 Nov, 2024</a>
                                <a href="blog-details.html"><i className="fa-light fa-comment"></i>3 Comments</a>
                            </div>
                            <h2 className="blog-title"><a href="blog-details.html">Strategies for Dominating Your Favorite Game</a>
                            </h2>
                            <p className="blog-text">Successful esports teams exhibit strong communication, strategic coordination, and individual player skills. Team chemistry, effective coaching, and adaptability to changing meats are also crucial factors.</p>
                            <a href="blog-details.html" className="link-btn style2">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                        </div>
                    </div>

                    <div className="th-blog blog-single has-post-thumbnail">
                        <div className="blog-img th-slider" data-slider-options='{"effect":"fade"}'>
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <a href="blog-details.html"><img src={Blogs1_2} alt="Blog-Image"/></a>
                                </div>
                                <div className="swiper-slide">
                                    <a href="blog-details.html"><img src={Blogs1_4} alt="Blog-Image"/></a>
                                </div>
                            </div>
                            <button className="slider-arrow slider-prev"><i className="far fa-arrow-left"></i></button>
                            <button className="slider-arrow slider-next"><i className="far fa-arrow-right"></i></button>
                        </div>
                        <div className="blog-content">
                            <div className="blog-meta">
                                <a className="author" href="blog.html"><i className="fa-light fa-user"></i>By Jonson</a>
                                <a href="blog.html"><i className="fa-light fa-calendar"></i>22 Dec, 2024</a>
                                <a href="blog-details.html"><i className="fa-light fa-comment"></i>3 Comments</a>
                            </div>
                            <h2 className="blog-title"><a href="blog-details.html">Influential Figures in the History of Gaming</a>
                            </h2>
                            <p className="blog-text">Providing opportunities for professional growth a maintaining positive work environment, To enhance online presence, consider optimizing your web utilizing social with your channels. An effective marketing involves market research target audience identification, competitive</p>
                            <a href="blog-details.html" className="link-btn style2">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                        </div>
                    </div>

                    <div className="th-blog blog-single">
                        <div className="blog-content">
                            <div className="blog-meta">
                                <a className="author" href="blog.html"><i className="fa-light fa-user"></i>By Jonson</a>
                                <a href="blog.html"><i className="fa-light fa-calendar"></i>24 June, 2024</a>
                                <a href="blog-details.html"><i className="fa-light fa-comment"></i>3 Comments</a>
                            </div>
                            <h2 className="blog-title"><a href="blog-details.html">Must-Visit Gaming Events Worldwide</a>
                            </h2>
                            <p className="blog-text">An effective marketing involves market research target audience identification, competitive. providing opportunities for professional growth a maintaining positive work environment. To enhance online presence, consider optimizing your web utilizing social with your channels</p>
                            <a href="blog-details.html" className="link-btn style2">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                        </div>
                    </div>

                    <div className="th-blog blog-single has-post-thumbnail">
                        <div className="blog-img" data-overlay="black" data-opacity="5">
                            <a href="blog-details.html"><img src={Blogs1_3} alt="Blog-Image"/></a>
                            <a href="https://www.youtube.com/watch?v=_sI_Ps7JSEk" className="play-btn popup-video"><i className="fas fa-play"></i></a>
                        </div>
                        <div className="blog-content">
                            <div className="blog-meta">
                                <a className="author" href="blog.html"><i className="fa-light fa-user"></i>By Jonson</a>
                                <a href="blog.html"><i className="fa-light fa-calendar"></i>09 Sep, 2024</a>
                                <a href="blog-details.html"><i className="fa-light fa-comment"></i>3 Comments</a>
                            </div>
                            <h2 className="blog-title"><a href="blog-details.html">Behind the Scenes of Your Favorite Titles</a>
                            </h2>
                            <p className="blog-text">From strategic planning to operational optimization, our business consulting team is committed to guiding you through every stage of development, ensuring sustainable growth.Our seasoned consultants bring a wealth of experience to the table.</p>
                            <a href="blog-details.html" className="link-btn style2">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                        </div>
                    </div>

                    <div className="th-blog blog-single has-post-thumbnail">
                        <div className="blog-audio">
                            <iframe title="Tell Me U Luv Me (with Trippie Redd) by Juice WRLD" src="https://w.soundcloud.com/player/?visual=true&amp;url=https%3A%2F%2Fapi.soundcloud.com%2Ftracks%2F830279092&amp;show_artwork=true&amp;maxwidth=751&amp;maxheight=1000&amp;dnt=1"></iframe>
                        </div>
                        <div className="blog-content">
                            <div className="blog-meta">
                                <a className="author" href="blog.html"><i className="fa-light fa-user"></i>By Jonson</a>
                                <a href="blog.html"><i className="fa-light fa-calendar"></i>10 Sep, 2024</a>
                                <a href="blog-details.html"><i className="fa-light fa-comment"></i>3 Comments</a>
                            </div>
                            <h2 className="blog-title"><a href="blog-details.html">Discover unparalleled expertise in market</a>
                            </h2>
                            <p className="blog-text">Take the first step towards a brighter business future. Contact us today to explore how our business consulting services can drive innovation, increase efficiency, and position your company for enduring success. At the core of our business consulting philosophy.</p>
                            <a href="blog-details.html" className="link-btn style2">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                        </div>
                    </div>

                    <div className="th-pagination ">
                        <ul>
                            <li><a href="blog.html"><span className="btn-border"></span> 1</a></li>
                            <li><a href="blog.html"><span className="btn-border"></span> 2</a></li>
                            <li><a href="blog.html"><span className="btn-border"></span> 3</a></li>
                            <li><a href="blog.html"><span className="btn-border"></span><i className="far fa-arrow-right"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-xxl-4 col-lg-5">
                    <aside className="sidebar-area">
                        <div className="widget widget_search  ">
                            <form className="search-form">
                                <input type="text" placeholder="Search here..."/>
                                <button type="submit"><i className="far fa-search"></i></button>
                            </form>
                        </div>
                        <div className="widget widget_categories  ">
                            <h3 className="widget_title">Categories</h3>
                            <ul>
                                <li>
                                    <a href="blog.html">LIVE GAME</a>
                                    <span>(2)</span>
                                </li>
                                <li>
                                    <a href="blog.html">FANTASY</a>
                                    <span>(2)</span>
                                </li>
                                <li>
                                    <a href="blog.html">GAMING</a>
                                    <span>(2)</span>
                                </li>
                                <li>
                                    <a href="blog.html">MX-XBOX</a>
                                    <span>(2)</span>
                                </li>
                                <li>
                                    <a href="blog.html">SHOOTING</a>
                                    <span>(2)</span>
                                </li>
                            </ul>
                        </div>
                        <div className="widget  ">
                            <h3 className="widget_title">Recent Posts</h3>
                            <div className="recent-post-wrap">
                                <div className="recent-post">
                                    <div className="media-img">
                                        <a href="blog-details.html"><img src={RecentPost1_1} alt="Blog-Image"/></a>
                                    </div>
                                    <div className="media-body">
                                        <h4 className="post-title"><a className="text-inherit" href="blog-details.html">A Day in the Life of an Esports Event</a></h4>
                                        <div className="recent-post-meta">
                                            <a href="blog.html"><i className="fa-light fa-calendar"></i>30 Nov, 2024</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="recent-post">
                                    <div className="media-img">
                                        <a href="blog-details.html"><img src={RecentPost1_2} alt="Blog-Image"/></a>
                                    </div>
                                    <div className="media-body">
                                        <h4 className="post-title"><a className="text-inherit" href="blog-details.html">Influential Figures in the History</a></h4>
                                        <div className="recent-post-meta">
                                            <a href="blog.html"><i className="fa-light fa-calendar"></i>05 Dec, 2024</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="recent-post">
                                    <div className="media-img">
                                        <a href="blog-details.html"><img src={RecentPost1_3} alt="Blog-Image"/></a>
                                    </div>
                                    <div className="media-body">
                                        <h4 className="post-title"><a className="text-inherit" href="blog-details.html">Behind the Scenes of Your Favorite</a></h4>
                                        <div className="recent-post-meta">
                                            <a href="blog.html"><i className="fa-light fa-calendar"></i>09 Sep, 2024</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="widget widget_tag_cloud  ">
                            <h3 className="widget_title">Popular Tags</h3>
                            <div className="tagcloud">
                                <a href="blog.html">GAME APP</a>
                                <a href="blog.html">E-SPORTS</a>
                                <a href="blog.html">TOURNAMENTS</a>
                                <a href="blog.html">MATCH</a>
                                <a href="blog.html">3D</a>
                                <a href="blog.html">GAME ANIMATION</a>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default BlogArea
