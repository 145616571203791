import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { BASE_URL } from '../ApiServices/apiFunctions';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [userProfile, setUserProfile] = useState(null);
    const [productDetails, setProductDetails] = useState([]);

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await fetch(`${BASE_URL}/marketplace/rewards`);
                if (!response.ok) {
                    throw new Error(`Failed to fetch rewards: ${response.status} ${response.statusText}`);
                }
                const data = await response.json();
                const vouchersWithSplitDenominations = data.rewards.data.getVouchers.data.flatMap((voucher) => {
                    const denominations = voucher.valueDenominations.split(',');
                    return denominations.map((denomination) => ({
                        ...voucher,
                        valueDenominations: denomination.trim(),
                    }));
                });
                setProductDetails(vouchersWithSplitDenominations);
            } catch (e) {
                console.error('Error fetching product details:', e);
            }
        };
        fetchProductDetails();
    }, []);

    // Memoize the context value to prevent unnecessary re-renders
    const value = useMemo(
        () => ({
            userProfile,
            setUserProfile,
            productDetails,
            setProductDetails,
        }),
        [userProfile, productDetails],
    );

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

// Custom hook to use the app context
export const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
