// import React from 'react';
// import '../../App.css';
// import $ from 'jquery'; // Import jQuery
// import Swiper from 'swiper';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'magnific-popup/dist/magnific-popup.css';
// import 'magnific-popup';
// import 'swiper/css'; // Import base CSS
// import 'swiper/css/navigation'; // Import navigation CSS if used
// import 'swiper/css/pagination'; // Import pagination CSS if used
// import { Link } from 'react-router-dom';
// import FeatureCardBg from '../../Assets/img/feature/feature-card-bg.png';
// import Feature1_1 from '../../Assets/img/feature/feature1-1.png';
// import Feature1_2 from '../../Assets/img/feature/feature1-2.png';
// import Feature1_3 from '../../Assets/img/feature/feature1-3.png';

// const ProGameCard1 = ({ 
//     gameId,
//     featureCardBg, 
//     featureImage, 
//     gameTitle, 
//     gameDescription, 
//     gameDetailsLink, 
//     playNowLink,
//     iconUrl
//   }) => {
//     return (
      
//         <div className="feature-game-card">
//           <div className="feature-game-card-img">
//             <Link to={{
//                 pathname: gameDetailsLink,
//                 state: { gameId: gameId }
//               }} onClick={(e) => e.stopPropagation()}>
//               <div className="feature-card-img-shape icon-masking">
//                 {/* <span className="mask-icon" data-mask-src={featureCardBg}></span>
//                 <img src={featureCardBg} alt="background" /> */}
//               </div>
//               <img src={featureImage} alt="game" />
//             </Link>
//             {/* <Link to={playNowLink} className="th-btn" onClick={(e) => e.stopPropagation()}>
//               PLAY NOW <i className="fa-solid fa-arrow-right ms-2"></i>
//             </Link> */}
//           </div>
//           <div className="feature-game-card-details">
//             <h3 className="box-title">
//             {gameTitle}
//             </h3>
//             {/* <p className="text">{gameDescription}</p> */}
//           </div>
//         </div>
     
//     );
//   };
  
//   export default ProGameCard1;



import React from 'react';
import '../../App.css';
import { useNavigate } from 'react-router-dom';

const ProGameCard1 = ({ 
    gameId,
    featureCardBg, 
    featureImage, 
    gameTitle, 
    gameDescription, 
    iconUrl
  }) => {
    const navigate = useNavigate();

    return (
      <div className="feature-game-card">
        <div className="feature-game-card-img">
          <div className="feature-card-img-shape icon-masking">
            {/* Image shape logic */}
          </div>
          <img src={featureImage} alt="game" />
        </div>
        <div className="feature-game-card-details">
          <h3 className="box-title">
            {gameTitle}
          </h3>
        </div>
      </div>
    );
  };

export default ProGameCard1;