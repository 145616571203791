import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './Utils/Contexts/authContext';
import { AppProvider } from './Utils/Contexts/appContext';
import ProtectedRoute from './Utils/Contexts/protectedRoute';

// Public Pages
import LandingPage from './Pages/PublicPages/LandingPage';
import FAQPage from './Pages/PublicPages/FAQPage';
import PrivacyPolicyPage from './Pages/PublicPages/PrivacyPolicyPage.js';
import TermsOfServicePage from './Pages/PublicPages/TermsOfServicePage.js';
import CookiePolicyPage from './Pages/PublicPages/CookiePolicyPage.js';
import AcceptableUsePolicyPage from './Pages/PublicPages/AcceptableUsePolicyPage.js';

import GamesPage from './Pages/PublicPages/GamesPage';
import LeaderBoardPage from './Pages/PublicPages/LeaderBoardPage';
import ShopDetailsPage from './Pages/PublicPages/ShopDetailsPage';
import ShopPage from './Pages/PublicPages/ShopPage';
import TournamentDetailsPage from './Pages/PublicPages/TournamentDetailsPage';

// Protected Pages
import ProLandingPage from './Pages/ProtectedPages/ProLandingPage';
import ProGamesPage from './Pages/ProtectedPages/ProGamesPage';
import ProShopPage from './Pages/ProtectedPages/ProShopPage';
import ProFAQPage from './Pages/ProtectedPages/ProFAQPage';
import ProTournamentDetailsPage from './Pages/ProtectedPages/ProTournamentDetailsPage';
import CartPage from './Pages/ProtectedPages/CartPage';
import CheckoutPage from './Pages/ProtectedPages/CheckoutPage';
import UserProfilePage from './Pages/ProtectedPages/UserProfilePage';

// Other Pages
import BlogDetailsPage from './Pages/OtherPages/BlogDetailsPage';
import BlogPage from './Pages/OtherPages/BlogPage';
import PartnerWithUs2 from './Components/UserProfilePageComp/PartnerWithUs/partnerWithUs';
import PartnerWithUs from './Components/UserProfilePageComp/PartnerWithUs/proPartnerWithUs';
import LoginPage from './Pages/OtherPages/LoginPage';

// Components
import ScrollToTop from './Components/Common/ScrollToTop';
import ConnectGameAccount from './Components/GamePageComp/connectGameAccount';
import FindGamerId from './Components/GamePageComp/findGamerId';
import TournamentsList2 from './Components/GamePageComp/tournamentsList2';
import ProfileForm from './Components/UserProfilePageComp/Form/ProfileForm';
import DetailedDash from './Components/UserProfilePageComp/DetailedDash/DetailedDash';
// import Dashboard from './Components/UserProfilePageComp/Dashboard/Dashboard';
import ProGlobalLeaderBoardPage from './Pages/ProtectedPages/ProGlobalLeaderBoardPage';
import TournamentsList1 from './Components/LandingPageComp/tournamentsList1';
import LogoutComponent from './Components/Common/LogoutComponent';
import StatComponent from './Components/UserProfilePageComp/Stats/StatComponent';
import ProUserRewardsPage from './Pages/ProtectedPages/ProUserRewardsPage';
import MainHeader1 from './Components/Common/MainHeader1';
import MainHeader2 from './Components/Common/MainHeader2';
import SimpleHeader1 from './Components/Common/SimpleHeader1';
import SimpleHeader2 from './Components/Common/SimpleHeader2';
import ProGameArea1 from './Components/ProtectedComponents/proGameArea1';
import ProGameArea2 from './Components/ProtectedComponents/proGameArea2';
import ProGameCard1 from './Components/ProtectedComponents/proGameCard1';
// import ProGameCard2 from './Components/ProtectedComponents/proGameCard2.js';
import ProTournamentsList1 from './Components/ProtectedComponents/proTournamentsList1';
import ProTournamentsList2 from './Components/ProtectedComponents/proTournamentsList2';
import ProTournamentCard7 from './Components/ProtectedComponents/proTournamentCard7';
import ProUserProfileFormPage from './Pages/ProtectedPages/ProUserProfileFormPage';
import ProDashboardPage from './Pages/ProtectedPages/ProDashboardPage';
import ProUserTournamentsPage from './Pages/ProtectedPages/ProUserTournamentsPage';
import ProductLightBox from './Components/LandingPageComp/productLightBox.js';
import ProUserWalletPage from './Pages/ProtectedPages/ProUserWalletPage.js';
import PremiumAccountPage from './Pages/ProtectedPages/PremiumAccountPage.js';
// import NextMatch from './Components/ProtectedComponents/NextMatch.js';
import NextMatchCard from './Components/ProtectedComponents/nextMatchCard.js';
import FindGameAccount from './Components/ProtectedComponents/FindGameAccount.js';

// import PostLandingPage from './Pages/Protected Pages/ProLandingPage'
// import GamesPage from './Pages/GamesPage';
// import LeaderBoardPage from './Pages/LeaderBoardPage';
// import ShopPage from './Pages/ShopPage';
// import UserProfilePage from './Pages/UserProfilePage';
// import TournamentDetailsPage from './Pages/TournamentDetailsPage';
// import ShopDetailsPage from './Pages/Public Pages/ShopDetailsPage';
// import ContactPage from './Pages/Other Pages/ContactPage';
// import CartPage from './Pages/Protected Pages/CartPage';
// import CheckoutPage from './Pages/CheckoutPage';
// import BlogPage from './Pages/BlogPage';
// import BlogDetailsPage from './Pages/Other Pages/BlogDetailsPage';
// import LoginPage from './Pages/Other Pages/LoginPage';

function App() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <AppProvider>
                    <div className="App">
                        <ScrollToTop />
                        <Routes>
                            {/* Public Pages Routes */}
                            <Route path="/" element={<LandingPage />} />
                            <Route path="/games" element={<GamesPage />} />
                            <Route
                                path="/tournament-details/:gameId/:tournamentId"
                                element={<TournamentDetailsPage />}
                            />
                            <Route path="/faq" element={<FAQPage />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                            <Route path="/terms-of-service" element={<TermsOfServicePage />} />
                            <Route path="/cookie-policy" element={<CookiePolicyPage />} />
                            <Route path="/acceptable-use-policy" element={<AcceptableUsePolicyPage />} />
                            <Route path="/partner-with-us" element={<PartnerWithUs2 />} />
                            <Route path="/leaderboard" element={<LeaderBoardPage />} />
                            {/* <Route path="/leaderboard/:gameId" element={<LeaderBoardPage />} /> */}
                            <Route path="/shop-details" element={<ShopDetailsPage />} />
                            <Route path="/shop" element={<ShopPage />} />
                            {/* Protected Pages Routes */}
                            <Route
                                path="/home"
                                element={
                                    <ProtectedRoute>
                                        <ProLandingPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/progames"
                                element={
                                    <ProtectedRoute>
                                        <ProGamesPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/proTournament-details/:gameId/:tournamentId"
                                element={
                                    <ProtectedRoute>
                                        <ProTournamentDetailsPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/proFAQ"
                                element={
                                    <ProtectedRoute>
                                        <ProFAQPage />
                                    </ProtectedRoute>
                                }
                            />
                            {/* <Route path='/proLeaderboard/:gameId' element={<ProtectedRoute><ProGlobalLeaderBoardPage/></ProtectedRoute>}/> */}
                            <Route
                                path="/cart"
                                element={
                                    <ProtectedRoute>
                                        <CartPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/checkout"
                                element={
                                    <ProtectedRoute>
                                        <CheckoutPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/proShop"
                                element={
                                    <ProtectedRoute>
                                        <ProShopPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/proPartner-with-us"
                                element={
                                    <ProtectedRoute>
                                        <PartnerWithUs />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/my-rewards"
                                element={
                                    <ProtectedRoute>
                                        <ProUserRewardsPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/my-profile"
                                element={
                                    <ProtectedRoute>
                                        <ProUserProfileFormPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/my-dashboard"
                                element={
                                    <ProtectedRoute>
                                        <ProDashboardPage />
                                    </ProtectedRoute>
                                }
                            />
                            {/* <Route path='/my-dashboard/:gameId' element={<ProtectedRoute><ProDashboardPage/></ProtectedRoute>}/> */}
                            {/* <Route path='/all-tournaments' element={<ProtectedRoute><ProTournamentsPage/></ProtectedRoute>}/> */}
                            {/* <Route path='/all-tournaments/:gameId' element={<ProtectedRoute><ProTournamentsPage/></ProtectedRoute>}/> */}
                            <Route
                                path="/my-tournaments"
                                element={
                                    <ProtectedRoute>
                                        <ProUserTournamentsPage />
                                    </ProtectedRoute>
                                }
                            />
                            {/* <Route path='/my-tournaments/:gameId' element={<ProtectedRoute><ProUserTournamentsPage/></ProtectedRoute>}/> */}
                            <Route path="/proleaderboard" element={<ProGlobalLeaderBoardPage />} />
                            {/* <Route path="/proleaderboard/:gameId" element={<ProGlobalLeaderBoardPage />} /> */}
                            <Route path="/my-wallet" element={<ProUserWalletPage />} />
                            <Route path="/premium-account" element={<PremiumAccountPage />} />
                            {/* Other Pages Routing */}
                            <Route path="/blog-details" element={<BlogDetailsPage />} />
                            <Route path="/blog" element={<BlogPage />} />
                            {/* <Route path='/contact' element={<ContactPage/>}/> */}
                            <Route path="/login" element={<LoginPage />} />
                            {/* Components Routing */}
                            {/* <Route path="/find-gamer-id" element={<FindGamerId/>} /> */}
                            <Route path="/logout" element={<LogoutComponent />} />
                            <Route path="/lightbox" element={<ProductLightBox />} />
                            <Route path="/next-match" element={<NextMatchCard />} />
                            <Route
                                path="/connect-game-account/:gameId"
                                element={
                                    <ProtectedRoute>
                                        <ConnectGameAccount />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/connect-game-account"
                                element={
                                    <ProtectedRoute>
                                        <ConnectGameAccount />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/find-gamer-id"
                                element={
                                    <ProtectedRoute>
                                        <FindGameAccount />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/profile-form"
                                element={
                                    <ProtectedRoute>
                                        <ProfileForm />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/stats"
                                element={
                                    <ProtectedRoute>
                                        <StatComponent />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/tournaments"
                                element={
                                    <ProtectedRoute>
                                        <TournamentsList1 />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/tournaments/:gameId"
                                element={
                                    <ProtectedRoute>
                                        <TournamentsList2 />
                                    </ProtectedRoute>
                                }
                            />
                            {/* <Route path="/my-tournaments" element={<ProtectedRoute><UserTournamentsList /></ProtectedRoute>} />
            <Route path="/my-tournaments/:gameId" element={<ProtectedRoute><UserTournamentsList /></ProtectedRoute>} />  */}
                            <Route
                                path="/progamearea1"
                                element={
                                    <ProtectedRoute>
                                        <ProGameArea1 />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/progamearea2"
                                element={
                                    <ProtectedRoute>
                                        <ProGameArea2 />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/progamecard1"
                                element={
                                    <ProtectedRoute>
                                        <ProGameCard1 />
                                    </ProtectedRoute>
                                }
                            />
                            {/* <Route path="/progamecard2" element={<ProtectedRoute><ProGameCard2 /></ProtectedRoute>} /> */}
                            <Route
                                path="/protournamentlist1"
                                element={
                                    <ProtectedRoute>
                                        <ProTournamentsList1 />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/protournamentlist2"
                                element={
                                    <ProtectedRoute>
                                        <ProTournamentsList2 />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/protournamentcard7"
                                element={
                                    <ProtectedRoute>
                                        <ProTournamentCard7 />
                                    </ProtectedRoute>
                                }
                            />
                            {/* <Route path='/' exact element={<LandingPage/>}/>
            <Route path='/game-details' element={<GameDetailsPage/>}/>
            <Route path='/post-landing' element={<PostLandingPage/>}/>
            <Route path='/games/:gameId' element={<GamesPage/>}/>
            <Route path='/games' element={<GamesPage/>}/>          
            <Route path='/tournament-details' element={<TournamentDetailsPage/>}/>
            <Route path='/shop' element={<ShopPage/>}/>
            <Route path='/shop-details' element={<ShopDetailsPage/>}/>
            <Route path='/leaderboard' element={<LeaderBoardPage/>}/>
            <Route path='/faq' element={<FAQPage/>}/>
            <Route path='/game-details' element={<GameDetails/>}/>
            <Route path='/contact' element={<ContactPage/>}/>
            <Route path='/cart' element={<CartPage/>}/>
            <Route path='/checkout' element={<CheckoutPage/>}/>
            <Route path='/blog' element={<BlogPage/>}/>
            <Route path='/blog-details' element={<BlogDetailsPage/>}/>
            <Route path='/login' element={<LoginPage/>}/>*/}
                            <Route path="/user-profile" element={<UserProfilePage />} />
                            {/* Headers Trial */}
                            <Route path="/mainheader1" element={<MainHeader1 />} /> {/* Working - Landing Page */}
                            <Route path="/mainheader2" element={<MainHeader2 />} /> {/* Working - ProLanding Page */}
                            <Route path="/simpleheader1" element={<SimpleHeader1 />} /> {/*   */}
                            <Route path="/simpleheader2" element={<SimpleHeader2 />} /> {/*   */}
                            {/* <Route path='/user-profile/sidenav' element={<SideNavbar/>}/>
            <Route path='/user-profile/rewards' element={<Rewards/>}/>
            <Route path='/user-profile/profile-form' element={<ProfileForm/>}/>*/}
                            <Route path="/user-profile/detailed-dash" element={<DetailedDash />} />
                            {/* <Route path='/user-profile/dashboard' element={<Dashboard/>}/>  */}
                            {/*
            <Route path='/wishlist' element={<WishlistPage/>}/>
            <Route path='/contact' element={<ContactPage/>}/>
            <Route path='/team-details' element={<TeamDetailsPage/>}/>
            <Route path='/service-details' element={<ServiceDetailsPage/>}/>
            <Route path='/UserProfilePage' element={<UserProfilePage/>}/>
            <Route path='/login' element={<LoginPage/>}/>
            <Route path='/signup' element={<SignupPage/>}/>
            <Route path='/verify-otp' element={<OTPVerification/>}/>
            <Route path='/protected' element={<ProtectedPage/>}/> */}
                        </Routes>
                    </div>
                </AppProvider>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
