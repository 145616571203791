import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../CustomCss/customStyles.css';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import GameCard6Mobile from '../MobileViewComp/gameCard6Mobile';
import { BASE_URL, fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import Feature1_1 from '../../Assets/img/feature/feature1-1.png';
import Feature1_2 from '../../Assets/img/feature/feature1-2.png';
import Feature1_3 from '../../Assets/img/feature/feature1-3.png';

const GameArea2Mobile = ({ callbackHandleClick }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [games, setGames] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGames = async () => {
            setIsLoading(true);
            try {
                const response = await fetchWithAuth(`${BASE_URL}/games`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });
                const data = await response.json();
                setGames(data.games); // No duplication for mobile view
            } catch (e) {
                setError(e.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchGames();
    }, []);

    useEffect(() => {
        if (games.length > 0) {
            new Swiper('.game-slider-mobile', {
                slidesPerView: 1,
                spaceBetween: 16,
                loop: true,
                speed: 800,
                autoplay: { delay: 5000, disableOnInteraction: false },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            });
        }
    }, [games]);

    const handlePlayNowClick = (gameId) => {
        callbackHandleClick(gameId);
    };

    if (isLoading) return <div>Loading.....</div>;
    if (error) return <div>Something went wrong! Please try again later..</div>;

    const featureImages = [Feature1_1, Feature1_3, Feature1_2];

    return (
        <section
            className="feature-game-area-mobile bg-black"
            style={{ backgroundColor: 'black', padding: '20px 0', marginTop: '300px' }}
        >
            <div className="container">
                <div className="title-area text-center" style={{ marginBottom: '20px' }}>
                    <span className="sub-title"># Epic Clash</span>
                    <h2 className="sec-title" style={{ fontWeight: '700' }}>
                        Game On, Level Up, Win Big <span className="text-theme">!</span>
                    </h2>
                </div>
                <div className="swiper game-slider-mobile">
                    <div className="swiper-wrapper">
                        {games.map((game, index) => (
                            <div className="" key={game.game_id}>
                                <GameCard6Mobile
                                    gameId={game.game_id}
                                    // featureCardBg={FeatureCardBg}
                                    featureImage={featureImages[index % featureImages.length]}
                                    gameTitle={game.name}
                                    // gameDescription={gameDescriptions[index % gameDescriptions.length]}
                                    gameDetailsLink={`/games`}
                                    playNowLink={`/games`}
                                    isAccountConnected={false}
                                    handlePlayNowClick={handlePlayNowClick}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
        </section>
    );
};

export default GameArea2Mobile;
