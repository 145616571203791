import React , {  } from 'react';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import AboutFeature1 from '../../Assets/img/icon/about_feature_1.svg';
import AboutFeature2 from '../../Assets/img/icon/about_feature_2.svg';
import AboutFeature3 from '../../Assets/img/icon/about_feature_3.svg';
import About11 from '../../Assets/img/normal/about1-1.png';


const AboutUs = () => {
  return (
    <>
     <div className="overflow-hidden space" id="about-sec" >
        <div className="about-bg-img shape-mockup" data-top="0" data-left="0">
            {/* <img src={AboutBg1} alt="img"/> */}
        </div>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-xl-6 mb-50 mb-xl-0">
                    <div className="img-box1">
                        <div className="img1 custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                            <img src={About11} alt="About"/>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="about-wrap1">
                        <div className="about-title-wrap mb-n1">
                            <div className="about-title-thumb custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.1s">
                                {/* <img src={About12} alt="img"/> */}
                            </div>
                            <div className="title-area custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.1s">
                                <span className="sub-title"># About AgonGames</span>
                                <h2 className="sec-title mb-0">Forging Legends in the Gaming Universe</h2>
                            </div>
                        </div>
                        <div className="about-grid">
                            <div className="icon custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                <img src={AboutFeature1} alt="img"/>
                            </div>
                            <div className="about-grid-details custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                <h3 className="about-grid_title h5"><span className="text-theme"></span> Global Competition:</h3>
                                <p className="about-grid_text">Compete with players worldwide in games like Clash Royale, Chess.com, COC and many more.</p>
                            </div>
                        </div>
                        <div className="about-grid">
                            <div className="icon custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                <img src={AboutFeature2} alt="img"/>
                            </div>
                            <div className="about-grid-details custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                <h3 className="about-grid_title h5">Data-Driven Fair Play:</h3>
                                <p className="about-grid_text">Our platform ensure every match is fair and data-backed.</p>
                            </div>
                        </div>
                        <div className="about-grid">
                            <div className="icon custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                <img src={AboutFeature3} alt="img"/>
                            </div>
                            <div className="about-grid-details custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                <h3 className="about-grid_title h5">Skill-Based Matchmaking:</h3>
                                <p className="about-grid_text">Get matched with players of similar skills and grow stronger with each victory.</p>
                            </div>
                        </div>
                        <div className="about-grid">
                            <div className="icon custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                <img src={AboutFeature3} alt="img"/>
                            </div>
                            <div className="about-grid-details custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                <h3 className="about-grid_title h5">Dynamic Leaderboards:</h3>
                                <p className="about-grid_text">Track your progress and see how you stack up against the competition.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    </>
  )
}

export default AboutUs
