import React, { useState, useEffect } from 'react';
import '../../App.css';
import '../../Assets/sass/style.scss';
import $ from 'jquery'; // Import jQuery
import Swiper from 'swiper';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import 'swiper/css'; // Import base CSS
import 'swiper/css/navigation'; // Import navigation CSS if used
import 'swiper/css/pagination'; // Import pagination CSS if used
import { Link } from 'react-router-dom';
import FeatureCardBg from '../../Assets/img/feature/feature-card-bg.png';
import Feature1_1 from '../../Assets/img/feature/feature1-1.png';
import Feature1_2 from '../../Assets/img/feature/feature1-2.png';
import Feature1_3 from '../../Assets/img/feature/feature1-3.png';
import { useNavigate } from 'react-router-dom';

const Card6 = ({
    gameId,
    featureCardBg,
    featureImage,
    gameTitle,
    gameDescription,
    gameDetailsLink,
    playNowLink,
    iconUrl,
    handlePlayNowClick,
}) => {
    const [accountConnected, setAccountConnected] = useState(false);
    // const [selectedGameId, setSelectedGameId] = useState(null);
    const navigate = useNavigate();

    console.log('Game Id in Card6 : ', gameId);

    // useEffect(() => {
    //   console.log("Game Id in Card6 : ", gameId)
    //   // Check if the gamer handle is stored in local storage
    //   const storedHandle = localStorage.getItem(`gamerHandle_${gameId}`);
    //   setAccountConnected(!!storedHandle); // Set connected state based on presence of handle
    // }, [gameId]);

    return (
        <div className="swiper-slide">
            <div
                class="th-product custom-game-container product-grid"
                style={{ backgroundColor: 'black', cursor: 'pointer' }}
                onClick={() => handlePlayNowClick(gameId)}
            >
                <div class="product-img custom-game-image">
                    <img src={featureImage} alt="game-image" />
                    <div className="overlay gradient-border"></div>
                </div>
                <div className="game-card-details">
                    <h3 className="box-title" style={{ marginTop: '50px' }}>
                        <div>{gameTitle}</div>
                    </h3>
                    {/* <p className="text">{gameDescription}</p> */}
                </div>
            </div>
        </div>
    );
};

export default Card6;
