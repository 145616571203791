import React, { useState } from 'react';
import '../../CustomCss/customStyles.css';

const HowItWorksMobile = () => {
    const [openStep, setOpenStep] = useState(1); // Default to Step 1

    const toggleStep = (step) => {
        setOpenStep((prev) => (prev === step ? null : step)); // Toggle step
    };

    return (
        <div className="how-it-works">
            <div className="sub-title style2 hiw-heading">
                <h2># HOW IT WORKS</h2>
            </div>

            <div className="timeline-item">
                <div className="circle"></div>
                <div className="content">
                    {/* <h3>Step 1<span className="highlight">COSTS</span></h3> */}
                    <h3 onClick={() => toggleStep(1)} style={{ cursor: 'pointer' }}>
                        Step 1
                    </h3>
                    {openStep === 1 && <p>Pick your favourite game and add your in game name</p>}
                </div>
            </div>

            <div className="timeline-item">
                <div className="circle"></div>
                <div className="content">
                    <h3 onClick={() => toggleStep(2)} style={{ cursor: 'pointer' }}>
                        Step 2
                    </h3>
                    {openStep === 2 && <p>Join the Tournament that fits your vibe. We’ve got tons of options!</p>}
                </div>
            </div>

            <div className="timeline-item">
                <div className="circle"></div>
                <div className="content">
                    <h3 onClick={() => toggleStep(3)} style={{ cursor: 'pointer' }}>
                        Step 3
                    </h3>
                    {openStep === 3 && <p>Keep grinding, climb the Leaderboard, and win Real Cash Rewards.</p>}
                </div>
            </div>
        </div>
    );
};

export default HowItWorksMobile;
