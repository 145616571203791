import React, { useEffect, useState } from 'react';
import '../../App.css';
import MobileMenu from '../LandingPageComp/mobileMenu';
import { Link } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap';
import BameLogo from '../../Assets/img/logo.jpg';

const SimpleHeader2 = ({ toggleMenu }) => {
    const [isSticky, setIsSticky] = useState(false);
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State for mobile menu

    const scrollToSection1 = (event) => {
        event.preventDefault(); // Prevent default anchor behavior
        const section = document.getElementById('games');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToSection2 = (event) => {
        event.preventDefault(); // Prevent default anchor behavior
        const section = document.getElementById('tournament');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 500) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Toggle category menu visibility
    const toggleCategoryMenu = (e) => {
        e.preventDefault();
        setIsCategoryOpen(!isCategoryOpen);
    };

    // Toggle mobile menu visibility
    const toggleMobileMenu = () => {
        console.log('Button is clicked');
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <>
            <header class={`th-header header-layout1 ${isSticky ? 'sticky' : ''}`}>
                <div className={`sticky-wrapper ${isSticky ? 'sticky' : ''}`}>
                    <div className="menu-area">
                        <div className="container">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto">
                                    <div className="header-logo">
                                        <Link to="/">
                                            {/* <span data-mask-src={BameLogo} className="logo-mask"></span> */}
                                            <img src={BameLogo} alt="Bame" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-auto" style={{ marginLeft: '0', textDecoration: 'none' }}>
                                    <nav
                                        className="main-menu d-none d-lg-inline-block"
                                        style={{ textDecoration: 'none', marginLeft: '0' }}
                                    >
                                        <ul>
                                            {/* className="menu-item-has-children" --> Remove bgcolor = yellow after getting the image */}
                                            {/* <li className="menu-item-has-children" style={{color:"black"}}> */}
                                            <li style={{ color: 'black' }}>
                                                <Link to="/">HOME</Link>
                                                {/* <ul className="sub-menu">
                                            <li><Link to = '/'>Home Esports</Link></li>
                                            <li><a href="home-2.html">Home Streaming</a></li>
                                            <li><a href="home-3.html">Home Video Gaming</a></li>
                                            <li><a href="home-4.html">Home Tournament</a></li>
                                            <li><a href="home-5.html">Home Gamer</a></li>
                                        </ul> */}
                                            </li>

                                            {/* <li className="menu-item-has-children">
                                        <a href="#how-it-works"  onClick={scrollToSection}>HOW IT WORKS</a></li> */}

                                            {/* <li className="menu-item-has-children"> */}
                                            {/* <li>
                                        <Link to='/games'>GAMES</Link></li> */}
                                            {/* <a href="#games"  onClick={scrollToSection1}>GAMES</a></li> */}

                                            {/* <li className="menu-item-has-children"> */}
                                            <li>
                                                <Link to="/games">TOURNAMENTS</Link>
                                                {/* <a href="#tournament"  onClick={scrollToSection2}>TOURNAMENTS</a> */}
                                                {/* <ul className="sub-menu">
                                            <li><Link to='/tournament'>Tournament</Link></li>
                                            <li><Link to='/tournament-details'>Tournament Details</Link></li>
                                        </ul> */}
                                            </li>
                                            {/* <li className="menu-item-has-children">
                                        <a href="#">BLOG</a>
                                        <ul className="sub-menu">
                                            <li><Link to='/blog'>Blog</Link></li>
                                            <li><a href="blog-details.html">Blog Details</a></li>
                                        </ul>
                                    </li> */}
                                            <li className="menu-item-has-children">
                                                <Link to="/shop">SHOP</Link>
                                                <ul className="sub-menu">
                                                    {/* <li><a href="shop.html">Shop</a></li> */}
                                                    {/* <li><Link to='/shop-details'>Shop Details</Link></li> */}
                                                    <li>
                                                        <Link to="/cart">Cart</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/checkout">Checkout</Link>
                                                    </li>
                                                    {/* <li><Link to='/wishlist'>Wishlist</Link></li> */}
                                                </ul>
                                            </li>
                                            {/* <li className="menu-item-has-children">
                                        <a href="#">SHOP</a>
                                        <ul className="sub-menu">
                                            <li className="menu-item-has-children">
                                                <a href="#">Shop</a>
                                                <ul className="sub-menu">
                                                    <li><a href="shop.html">Shop</a></li>
                                                    <li><a href="shop-details.html">Shop Details</a></li>
                                                    <li><a href="cart.html">Cart Page</a></li>
                                                    <li><a href="checkout.html">Checkout</a></li>
                                                    <li><a href="wishlist.html">Wishlist</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="team.html">Players</a></li>
                                            <li><a href="team-details.html">Players Details</a></li>
                                            <li><a href="game.html">Game</a></li>
                                            <li><a href="game-details.html">Game Details</a></li>
                                            <li><a href="gallery.html">Gallery</a></li>
                                            <li><a href="point-table.html">Point Table</a></li>
                                            <li><a href="error.html">Error Page</a></li>
                                        </ul>
                                    </li> */}
                                            {/* <li><Link to='/leaderboard'>LEADERBOARD</Link></li> */}
                                            <li>
                                                <Link to="/partner">PARTNER</Link>
                                            </li>
                                            {/* <li><Link to='/partner'>AGONTRIBE LEADS</Link></li> */}
                                            <li>
                                                <button
                                                    type="button"
                                                    className="simple-icon sideMenuInfo header-button"
                                                    onClick={toggleMenu}
                                                >
                                                    <i className="fa-solid fa-bars"></i>
                                                </button>
                                            </li>
                                            <li className="btn-group">
                                                <Link
                                                    to="/login"
                                                    className="th-btn"
                                                    style={{ padding: '20px', color: 'black' }}
                                                >
                                                    Login
                                                </Link>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className="header-button d-flex d-lg-none">
                                        <button type="button" className="th-menu-toggle" onClick={toggleMobileMenu}>
                                            <span className="btn-border"></span>
                                            <i className="far fa-bars"></i>
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className="col-auto d-none d-xl-block"
                                    style={{ marginLeft: '1100px', marginBottom: '10px' }}
                                >
                                    {/* <div className="header-button">
                                <button type="button" className="simple-icon searchBoxToggler">
                                <i className="far fa-search"></i>
                                </button>
                                <button type="button" className="simple-icon sideMenuInfo" onClick={toggleMenu}>
                                <i className="fa-solid fa-bars"></i>
                                </button>
                                <div className="d-xxl-block d-none">
                                <a href="contact.html" className="th-btn">
                                    <i className="fa-brands fa-twitch me-1"></i> Live Streaming
                                </a>
                                </div>
                            </div> */}
                                </div>
                            </div>
                        </div>
                        <div className={`category-menu ${isCategoryOpen ? 'open-category' : 'close-category'}`}>
                            {/* Your category menu content here */}
                        </div>
                        <div className="logo-bg"></div>
                    </div>
                </div>
            </header>
            {/* Mobile Menu Sliding Effect */}
            <MobileMenu isOpen={isMobileMenuOpen} toggleMenu={toggleMobileMenu} />
        </>
    );
};

export default SimpleHeader2;
