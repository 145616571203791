// import React, { useState } from 'react';
// import { FaTrophy, FaChevronRight, FaUser, FaCog, FaBell, FaSignOutAlt, FaChartLine, FaGamepad, FaShoppingCart, FaEnvelope, FaShieldAlt , FaUsers , FaGift } from 'react-icons/fa'; // Import suitable icons
// import { useNavigate } from 'react-router-dom'; // Import useNavigate
// import './SideNav.css'; // Import CSS file for styles
// // import '../../../App.css';
// import PropTypes from 'prop-types';

// const SideNavbar = ({ isOpen, toggleSideNavbar, gameId }) => {
//   const [profile, setProfile] = useState({
//     username: 'Sruteesh',
//     proficiency: 'Beginner',
//     image: null,
//   });

//   const navigate = useNavigate(); // Initialize useNavigate

//   const handleImageChange = (e) => {
//     if (e.target.files && e.target.files[0]) {
//       setProfile({
//         ...profile,
//         image: URL.createObjectURL(e.target.files[0]),
//       });
//     }
//   };

//   const handleEditProfile = () => {
//     // Logic to open edit profile popup
//     navigate(`/my-dashboard`)
//   };

//   const handleLogoutClick = () => {
//     // Show confirmation dialog before logout
//     const confirmed = window.confirm('Are you sure you want to logout?');
//     if (confirmed) {
//       // Perform logout action, like clearing user data or tokens
//       localStorage.removeItem('accessToken'); 
//       navigate('/'); 
//     }
//   };

//   // Dummy menu options with suitable icons
//   const menuOptions = [
//     { icon: <FaGift className="menu-icon" />, label: 'My Rewards', route: '/my-rewards' },    
//     { icon: <FaTrophy className="menu-icon" />, label: 'My Tournaments', route: '/my-tournaments' },
//     { icon: <FaShoppingCart className="menu-icon" />, label: 'Order History' },
//     { icon: <FaShoppingCart className="menu-icon" />, label: 'My Cart', route: '/Cart' },
//     { icon: <FaShoppingCart className="menu-icon" />, label: 'My Wishlist' },
//     { icon: <FaSignOutAlt className="menu-icon" />, label: 'Logout', route: '/logout' },

//     // { icon: <FaUser className="menu-icon" />, label: 'Profile' },
//     // { icon: <FaGamepad className="menu-icon" />, label: 'Games' },
//     // { icon: <FaTrophy className="menu-icon" />, label: 'Achievements' },
//     // { icon: <FaBell className="menu-icon" />, label: 'Notifications' },
//     // { icon: <FaCog className="menu-icon" />, label: 'Settings' },
//     // { icon: <FaEnvelope className="menu-icon" />, label: 'Messages' },
//     // { icon: <FaShieldAlt className="menu-icon" />, label: 'Privacy' },
//     // { icon: <FaUsers className="menu-icon" />, label: 'My Teams' },
//   ];


//   return (
//     <div className='side-navbar-container' style={{backgroundColor:"black"}}>
//     <div className={`side-navbar ${isOpen ? 'open' : 'closed'}`}>
//       <div className="profile-section">
//         <input 
//           type="file" 
//           id="upload" 
//           onChange={handleImageChange} 
//           style={{ display: 'none' }} 
//         />
//         <label htmlFor="upload" className="profile-picture">
//           <img
//             src={profile.image || 'https://via.placeholder.com/80'}
//             alt="Profile"
//           />
//         </label>
//         <div className="profile-info">
//           <h4 onClick={handleEditProfile}>{profile.username}</h4>
//           <p onClick={handleEditProfile}>{profile.proficiency}</p>
//         </div>
//         <FaChevronRight className="edit-icon" onClick={handleEditProfile} />
//       </div>

//       <div className="menu-options">
//         {menuOptions.map((item, index) => (
//           <div className="menu-item" 
//           key={index}
//           onClick={item.label === 'Logout' ? handleLogoutClick : () => navigate(item.route)} 
//           >
//             {item.icon}
//             <span>{item.label}</span>
//           </div>
//         ))}
//       </div>
//     </div>
//     {/* <button onClick={toggleSideNavbar}>Close Menu</button> */}
//     </div>
//   );
// };

// SideNavbar.propTypes = {
//   gameId: PropTypes.string.isRequired,
// };

// export default SideNavbar;



// // import React, { useState } from 'react';
// // // import ConfirmationModal from './ConfirmationModal'; // Import the ConfirmationModal component
// // import { FaTrophy, FaChevronRight, FaUser, FaCog, FaBell, FaSignOutAlt, FaChartLine, FaGamepad, FaShoppingCart, FaEnvelope, FaShieldAlt , FaUsers , FaGift } from 'react-icons/fa';
// // import { useNavigate } from 'react-router-dom';
// // import './SideNav.css';
// // import PropTypes from 'prop-types';
// // import LogoutConfirmationModal from '../../ProtectedComponents/logoutConfirmationModal';

// // const SideNavbar = ({ isOpen, toggleSideNavbar, gameId }) => {
// //   const [profile, setProfile] = useState({
// //     username: 'Sruteesh',
// //     proficiency: 'Beginner',
// //     image: null,
// //   });

// //   const navigate = useNavigate();
// //   const [showLogoutModal, setShowLogoutModal] = useState(false); // Modal visibility state

// //   const handleImageChange = (e) => {
// //     if (e.target.files && e.target.files[0]) {
// //       setProfile({
// //         ...profile,
// //         image: URL.createObjectURL(e.target.files[0]),
// //       });
// //     }
// //   };

// //   const handleEditProfile = () => {
// //     navigate(`/my-dashboard`);
// //   };

// //   const handleLogoutClick = () => {
// //     setShowLogoutModal(true); // Show the custom logout confirmation modal
// //   };

// //   const confirmLogout = () => {
// //     localStorage.removeItem('accessToken');
// //     navigate('/');
// //   };

// //   const cancelLogout = () => {
// //     setShowLogoutModal(false); // Hide the modal if the user cancels
// //   };

// //   const menuOptions = [
// //     { icon: <FaGift className="menu-icon" />, label: 'My Rewards', route: '/my-rewards' },    
// //     { icon: <FaTrophy className="menu-icon" />, label: 'My Tournaments', route: '/my-tournaments' },
// //     { icon: <FaShoppingCart className="menu-icon" />, label: 'Order History' },
// //     { icon: <FaShoppingCart className="menu-icon" />, label: 'My Cart', route: '/Cart' },
// //     { icon: <FaShoppingCart className="menu-icon" />, label: 'My Wishlist' },
// //     { icon: <FaSignOutAlt className="menu-icon" />, label: 'Logout', route: '/logout' },
// //   ];

// //   return (
// //     <div className='side-navbar-container' style={{backgroundColor:"black"}}>
// //       <div className={`side-navbar ${isOpen ? 'open' : 'closed'}`}>
// //         <div className="profile-section">
// //           <input 
// //             type="file" 
// //             id="upload" 
// //             onChange={handleImageChange} 
// //             style={{ display: 'none' }} 
// //           />
// //           <label htmlFor="upload" className="profile-picture">
// //             <img
// //               src={profile.image || 'https://via.placeholder.com/80'}
// //               alt="Profile"
// //             />
// //           </label>
// //           <div className="profile-info">
// //             <h4 onClick={handleEditProfile}>{profile.username}</h4>
// //             <p onClick={handleEditProfile}>{profile.proficiency}</p>
// //           </div>
// //           <FaChevronRight className="edit-icon" onClick={handleEditProfile} />
// //         </div>

// //         <div className="menu-options">
// //           {menuOptions.map((item, index) => (
// //             <div 
// //               className="menu-item" 
// //               key={index}
// //               onClick={item.label === 'Logout' ? handleLogoutClick : () => navigate(item.route)} 
// //             >
// //               {item.icon}
// //               <span>{item.label}</span>
// //             </div>
// //           ))}
// //         </div>
// //       </div>
      
// //       {showLogoutModal && (
// //         <LogoutConfirmationModal 
// //           message="Are you sure you want to logout?" 
// //           onConfirm={confirmLogout} 
// //           onCancel={cancelLogout} 
// //         />
// //       )}
// //     </div>
// //   );
// // };

// // SideNavbar.propTypes = {
// //   gameId: PropTypes.string.isRequired,
// // };

// // export default SideNavbar;




import React, { useState, useEffect, useContext } from 'react';
import { FaTrophy, FaChevronRight, FaUser, FaCog, FaBell, FaSignOutAlt, FaChartLine, FaGamepad, FaShoppingCart, FaEnvelope, FaShieldAlt, FaUsers, FaGift } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './SideNav.css';
import PropTypes from 'prop-types';
import { AuthContext } from '../../../Utils/Contexts/authContext';
import { BASE_URL } from '../../../Utils/ApiServices/apiFunctions';

const SideNavbar = ({ isOpen, toggleSideNavbar, gameId }) => {
  const { logout, accessToken } = useContext(AuthContext);
  const [profile, setProfile] = useState({
    username: '',  // This will be set from display_name
    proficiency: 'Beginner',
    image: null,
  });
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  // Fetch user profile from API
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(`${BASE_URL}/user/profile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
          }
        });
        const data = await response.json();
        
        if (data.code === 200) {
          const { display_name } = data.user_data;
          setProfile((prevProfile) => ({
            ...prevProfile,
            username: display_name,
          }));
        } else {
          throw new Error('Failed to fetch profile data');
        }
      } catch (e) {
        console.error(e);
        setError('Error fetching profile data');
      }
    };

    fetchProfile();
  }, []);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProfile({
        ...profile,
        image: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const handleEditProfile = () => {
    navigate(`/my-dashboard`);
  };

  const handleLogoutClick = () => {
    const confirmed = window.confirm('Are you sure you want to logout?');
    if (confirmed) {
      logout();
    }
  };

  const menuOptions = [
    { icon: <FaGift className="menu-icon" />, label: 'My Rewards', route: '/my-rewards' },
    { icon: <FaTrophy className="menu-icon" />, label: 'My Tournaments', route: '/my-tournaments' },
    { icon: <FaShoppingCart className="menu-icon" />, label: 'Order History' },
    { icon: <FaShoppingCart className="menu-icon" />, label: 'My Cart', route: '/Cart' },
    { icon: <FaShoppingCart className="menu-icon" />, label: 'My Wishlist' },
    { icon: <FaSignOutAlt className="menu-icon" />, label: 'Logout', route: '/logout' },
  ];

  return (
    <div className='side-navbar-container' style={{ backgroundColor: "black" }}>
      <div className={`side-navbar ${isOpen ? 'open' : 'closed'}`}>
        <div className="profile-section" style={{cursor: 'pointer'}} onClick={handleEditProfile}>
          <input
            type="file"
            id="upload"
            // onChange={handleImageChange}
            style={{ display: 'none' }}
          />
          <label htmlFor="upload" className="profile-picture">
            <img
              src={profile.image || 'https://via.placeholder.com/80'}
              alt="Profile"
            />
          </label>
          <div className="profile-info">
            <h4>{profile.username || 'User'}</h4>
            <p>{profile.proficiency}</p>
          </div>
          <FaChevronRight className="edit-icon"/>
        </div>

        <div className="menu-options">
          {menuOptions.map((item, index) => (
            <div
              className="menu-item"
              key={index}
              onClick={item.label === 'Logout' ? handleLogoutClick : () => navigate(item.route)}
            >
              {item.icon}
              <span>{item.label}</span>
            </div>
          ))}
        </div>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

SideNavbar.propTypes = {
  gameId: PropTypes.string.isRequired,
};

export default SideNavbar;
