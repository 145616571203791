import React, {useState} from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'jquery-ui/ui/widgets/slider'; // Example widget
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import ColorSwitcher from '../../Components/Common/ColorSwitcher';
import Footer2 from '../../Components/Common/Footer2';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import ProductsArea from '../../Components/ShopPageComp/productsArea';
// import SimpleHeader2 from '../../Components/Common/SimpleHeader2';
import SimpleHeader1 from '../../Components/Common/SimpleHeader1';
import SimpleHeader2 from '../../Components/Common/SimpleHeader2';




const ShopPage = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
     <Cursor/>
      <Preloader/>
      <SimpleHeader2 toggleMenu={toggleMenu}/>
      <SideMenu2 isMenuOpen={isMenuOpen} toggleMenu={toggleMenu}/>
      <ColorSwitcher/>
      <ProductsArea/>
      <Footer2/>
      <ScrollToTop/> 
    </>
  )
}

export default ShopPage
