import React, { useEffect, useState, useContext } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import BameLogo from '../../Assets/img/logo.jpg';
import MobileMenu from '../LandingPageComp/mobileMenu';
import { AuthContext } from '../../Utils/Contexts/authContext';
import SideNavbar from '../UserProfilePageComp/SideNavbar/SideNavbar';
import BonusCash from '../../Assets/img/wallet/bonus_cash.png';
import RealCash from '../../Assets/img/wallet/real_cash.png';
import PremiumIcon from '../../Assets/img/icon/agon_premium_icon.png';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../Utils/Contexts/appContext';

import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';

const MainHeader2 = ({ toggleMenu }) => {
    const navigate = useNavigate();
    const { accessToken } = useContext(AuthContext); // Authentication token
    const [isSticky, setIsSticky] = useState(false);
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State for mobile menu
    const [isSideNavbarOpen, setIsSideNavbarOpen] = useState(false); // State for SideNavbar visibility
    const { userProfile, setUserProfile } = useAppContext();
    const [isLoading, setIsLoading] = useState(true); // State to show loading status
    const [error, setError] = useState(null); // State to hold any error

    useEffect(() => {
        const fetchWalletData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/user/profile`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) throw new Error('Failed to fetch wallet data');

                const data = await response.json();
                console.log('User Wallet data from the backend : ', data.user_data);
                // setWalletData({
                //     bonus_wallet: data.user_data.bonus_wallet,
                //     deposit_wallet: data.user_data.deposit_wallet,
                //     winnings_wallet: data.user_data.winnings_wallet,
                // });
                setUserProfile(data.user_data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching wallet data:', error);
                setError(error.message);
                setIsLoading(false);
            }
        };

        fetchWalletData();
    }, [accessToken]);

    const scrollToSection = (event) => {
        event.preventDefault(); // Prevent default anchor behavior
        const section = document.getElementById('how-it-works');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 500) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Toggle category menu visibility
    const toggleCategoryMenu = (e) => {
        e.preventDefault();
        setIsCategoryOpen(!isCategoryOpen);
    };

    // Toggle mobile menu visibility
    const toggleMobileMenu = () => {
        console.log('Button is clicked');
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const toggleSideNavbar = () => {
        setIsSideNavbarOpen(!isSideNavbarOpen);
    };

    return (
        <>
            <header className={`th-header header-layout1 ${isSticky ? 'sticky' : ''}`}>
                {/* Main Menu Area */}
                <div className={`sticky-wrapper ${isSticky ? 'sticky' : ''}`}>
                    <div className="menu-area">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <div className="header-logo">
                                        <Link to="/home">
                                            {/* <span data-mask-src={BameLogo} className="logo-mask"></span> */}
                                            <img src={BameLogo} alt="Bame" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <nav className="main-menu d-none d-lg-inline-block" style={{ marginLeft: '120px' }}>
                                        <ul>
                                            <li>
                                                <Link to="/home">HOME</Link>
                                            </li>
                                            <li>
                                                <Link to="/progames">TOURNAMENTS</Link>
                                            </li>
                                            <li>
                                                <Link to="/proShop">SHOP</Link>
                                            </li>
                                            <li>
                                                <Link to="/proleaderboard">LEADERBOARD</Link>
                                            </li>
                                            {/* <li><Link to="/my-tournaments">MY TOURNAMENTS</Link></li> */}
                                            <li>
                                                <Link to="/my-rewards">MY REWARDS</Link>
                                            </li>
                                            <li>
                                                <Link to="/proPartner-with-us">PARTNER WITH US</Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="col-auto d-flex align-items-center" style={{ 'margin-left': 'auto' }}>
                                    {/* <nav className="main-menu d-none d-lg-inline-block">
                                        <ul>
                                            <li> */}
                                    <Link to="/premium-account" style={{ marginRight: '0px' }}>
                                        <img
                                            src={PremiumIcon}
                                            alt="Agon Premium"
                                            style={{
                                                height: '24px',
                                                width: 'auto',
                                                verticalAlign: 'middle',
                                            }}
                                        />
                                    </Link>
                                    <a href="#" onClick={toggleSideNavbar}>
                                        <i className="fa-solid fa-user"></i>
                                    </a>

                                    {/* </li> */}
                                    {/* Wallet UI */}
                                    {/* <li className="wallet-ui"> */}
                                    {isLoading ? (
                                        <span>Loading...</span>
                                    ) : (
                                        <Link to="/my-wallet">
                                            <div
                                                className="wallet-info"
                                                style={{
                                                    'margin-left': '40px',
                                                }}
                                            >
                                                {/* <span className="wallet-item">
                                              <i className="fa-solid fa-wallet"></i> ₹{walletData.deposit_wallet}
                                            </span> */}
                                                <span className="wallet-item">
                                                    <img
                                                        style={{
                                                            height: 18,
                                                        }}
                                                        src={BonusCash}
                                                        alt="bonus"
                                                    />{' '}
                                                    {userProfile?.bonus_wallet || 0}
                                                </span>
                                                <span className="wallet-item">
                                                    <img
                                                        style={{
                                                            height: 17,
                                                            paddingLeft: 5,
                                                            paddingBottom: 3,
                                                        }}
                                                        src={RealCash}
                                                        alt="real"
                                                    />{' '}
                                                    {userProfile?.winnings_wallet || 0}
                                                </span>
                                            </div>
                                        </Link>
                                    )}
                                    {/* </li>
                                        </ul>
                                    </nav> */}
                                </div>

                                <div className="header-button d-flex d-lg-none">
                                    <button className="th-menu-toggle" onClick={toggleMobileMenu}>
                                        <i className="far fa-bars"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={`category-menu ${isCategoryOpen ? 'open-category' : 'close-category'}`}>
                            {/* Your category menu content here */}
                        </div>
                        <div className="logo-bg"></div>
                    </div>
                </div>
            </header>

            {/* Mobile Menu Sliding Effect */}
            <MobileMenu isOpen={isMobileMenuOpen} toggleMenu={toggleMobileMenu} />

            {/* SideNavbar */}
            {isSideNavbarOpen && <SideNavbar />}
        </>
    );
};

export default MainHeader2;
