import React , { useEffect, useState } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap'; 

const MobileMenu = ({ isOpen, toggleMenu }) => {

//     const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [activeSubMenus, setActiveSubMenus] = useState([]);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//     // console.log("Mobile menu is open");
//   };

//   const toggleSubMenu = (index) => {
//     setActiveSubMenus((prevActive) =>
//       prevActive.includes(index)
//         ? prevActive.filter((i) => i !== index)
//         : [...prevActive, index]
//     );
//   };


  return (
    <>
      <div className={`th-menu-wrapper ${isOpen ? 'th-body-visible' : ''}`}>
        <div className="th-menu-area text-center">
            <button className="th-menu-toggle" onClick={toggleMenu}><i className="fal fa-times"></i></button>
            <div className="mobile-logo">
            <Link to="/">
                <span data-mask-src="../../Assets/img/logo.jpg" className="logo-mask"></span>
                <img src="assets/img/logo.jpg" alt="Bame"/>
            </Link>
            </div>
            <div className="th-mobile-menu">
                <ul>
                    <li className="menu-item-has-children">
                    <Link to = '/'>HOME</Link>
                        {/* <ul className={`sub-menu ${activeSubMenus.includes(0) ? 'th-open' : ''}`}
                style={{ display: activeSubMenus.includes(0) ? 'block' : 'none' }}>
                        <li><Link to = '/'>Home Esports</Link></li> */}
                            {/* <li><a href="home-2.html">Home Streaming</a></li>
                            <li><a href="home-3.html">Home Video Gaming</a></li>
                            <li><a href="home-4.html">Home Tournament</a></li>
                            <li><a href="home-5.html">Home Gamer</a></li> 
                        </ul>*/}
                    </li>
                    <li><Link to='/about-us'>ABOUT US</Link></li>
                    <li className="menu-item-has-children">
                    {/* <Link to='/tournament' onClick={() => toggleSubMenu(1)}>TOURNAMENT</Link>
                        <ul className={`sub-menu ${activeSubMenus.includes(0) ? 'th-open' : ''}`}
                style={{ display: activeSubMenus.includes(0) ? 'block' : 'none' }}> */}
                            <li><Link to='/tournament'>Tournament</Link></li>
                            <li><Link to='/tournament-details'>Tournament Details</Link></li>
                        {/* </ul> */}
                    </li>
                    {/* <li className="menu-item-has-children">
                        <a href="#">BLOG</a>
                        <ul className="sub-menu">
                            <li><a href="blog.html">Blog</a></li>
                            <li><a href="blog-details.html">Blog Details</a></li>
                        </ul>
                    </li> */}
                    <li className="menu-item-has-children">
                        {/* <Link to='/shop' onClick={() => toggleSubMenu(1)}>SHOP</Link>
                            <ul className={`sub-menu ${activeSubMenus.includes(0) ? 'th-open' : ''}`}
                style={{ display: activeSubMenus.includes(0) ? 'block' : 'none' }}> */}
                            {/* <li><a href="shop.html">Shop</a></li> */}
                                <li><Link to='/shop-details'>Shop Details</Link></li>
                                <li><Link to='/cart'>Cart</Link></li>
                                <li><Link to='/checkout'>Checkout</Link></li>
                                <li><Link to='/wishlist'>Wishlist</Link></li>
                            {/* </ul> */}
                    </li>
                    {/* <li className="menu-item-has-children">
                        <a href="#">PAGES</a>
                        <ul className="sub-menu">
                            <li className="menu-item-has-children">
                                <a href="#">Shop</a>
                                <ul className="sub-menu">
                                    <li><a href="shop.html">Shop</a></li>
                                    <li><a href="shop-details.html">Shop Details</a></li>
                                    <li><a href="cart.html">Cart Page</a></li>
                                    <li><a href="checkout.html">Checkout</a></li>
                                    <li><a href="wishlist.html">Wishlist</a></li>
                                </ul>
                            </li>
                            <li><a href="team.html">Players</a></li>
                            <li><a href="team-details.html">Players Details</a></li>
                            <li><a href="game.html">Game</a></li>
                            <li><a href="game-details.html">Game Details</a></li>
                            <li><a href="gallery.html">Gallery</a></li>
                            <li><a href="point-table.html">Point Table</a></li>
                            <li><a href="error.html">Error Page</a></li>
                        </ul>
                    </li> */}

                    <li>
                    <Link to='/contact'>CONTACT</Link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div className="color-scheme-wrap active">
        <button className="switchIcon"><i className="fa-solid fa-palette"></i></button>
        <h4 className="color-scheme-wrap-title"><i className="far fa-palette"></i> Color Switcher</h4>
        <div className="color-switch-btns">
            <button data-color="#6240CF"><i className="fa-solid fa-droplet"></i></button>
            <button data-color="#FFBE18"><i className="fa-solid fa-droplet"></i></button>
            <button data-color="#24FFF2"><i className="fa-solid fa-droplet"></i></button>
            <button data-color="#45F882"><i className="fa-solid fa-droplet"></i></button>
            <button data-color="#FF7E02"><i className="fa-solid fa-droplet"></i></button>
        </div>
    </div>
    </>
  )
}

export default MobileMenu
