import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../Utils/Contexts/authContext';
import '../../App.css';
import '../../CustomCss/customStyles.css';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import GameVS1 from '../../Assets/img/tournament/game-vs1.svg';
import TournamentCard2Bg from '../../Assets/img/bg/tournament-card2-bg.png';
import TournamentSec2Bg from '../../Assets/img/bg/tournament-sec2-bg.png';
import imagesLoaded from 'imagesloaded';
import Card2 from '../Common/Card2';
import Card3 from '../Common/Card3';
import ProTournamentCard7 from '../ProtectedComponents/proTournamentCard7';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';
window.jQuery = $;
require('isotope-layout/dist/isotope.pkgd.js');
imagesLoaded.makeJQueryPlugin($);

const ProTournamentsList2 = ({ selectedGameId, gameHandles, landing, pageType }) => {
    let gameId = selectedGameId;
    const { accessToken } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [tournaments, setTournaments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [totalPages, setTotalPages] = useState(1); // Total pages from API response
    const [pageSize, setPageSize] = useState(10); // Number of tournaments per page
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState('upcoming'); // Default filter is 'upcoming'
    const [activeFilter, setActiveFilter] = useState('upcoming'); // Track the active filter button

    console.log({
        status: selectedFilter,
        pageSize,
        currentPage,
    });
    console.log('access token', accessToken);

    // Function to map game names to folder names
    const mapGameToFolder = (gameName) => {
        switch (gameName) {
            case 'Clash Royale':
                return 'Clash Royale';
            case 'Clash of Clans':
                return 'Clash of clans';
            case 'Chess':
                return 'Chess';
            default:
                return 'default'; // Fallback folder
        }
    };

    // Updated getImage function to handle different game folders
    const getImage = (gameName, iconName) => {
        const folder = mapGameToFolder(gameName); // Get the folder based on the game name
        try {
            return require(`../../Assets/img/tournament/${folder}/${iconName}`);
        } catch (err) {
            console.error(`Image ${iconName} not found in ${folder}, using default.`);
            return require('../../Assets/img/tournament/Chess/1_1.png'); // Default fallback image
        }
    };

    useEffect(() => {
        const fetchTournaments = async () => {
            setIsLoading(true);
            try {
                let url = '';
                if (selectedFilter === 'my-tournaments') {
                    // Fetch user-registered tournaments
                    url = `${BASE_URL}/user/tournaments?status=all&page_size=${pageSize}&page=${currentPage}`;
                } else {
                    console.log('Selected Game ID', gameId);
                    // Fetch general tournaments based on selected filter
                    url = `${BASE_URL}/tournaments?landing=${landing}&status=${selectedFilter}&page_size=${pageSize}&page=${currentPage}&gameId=${gameId}`;
                }

                const headers = {
                    'Content-Type': 'application/json',
                };

                if (accessToken) {
                    headers['Authorization'] = `Bearer ${accessToken}`;
                }

                const response = await fetch(url, {
                    method: 'GET',
                    headers: headers,
                });
                // console.log("response - tournamentsList2 :" , response)

                if (!response.ok) {
                    console.log('error', response);
                    throw new Error(`Failed to fetch tournaments: ${response.status} ${response.statusText}`);
                }

                const data = await response.json();
                if (Array.isArray(data.tournaments)) {
                    setTournaments(data.tournaments); // Set the array of tournaments
                    setTotalPages(data.total_pages);
                } else {
                    setTournaments([]); // If not an array, set it to an empty array
                    throw new Error('Tournaments data is not in the expected format.');
                }
            } catch (e) {
                setError(e.message);
            } finally {
                setIsLoading(false);
            }
            const hasScrolled = window.scrollY > 0;
            if (!hasScrolled) {
                $('html, body').animate({ scrollTop: $('#tournament').offset().top }, 1000);
            }
            // $('html, body').animate({ scrollTop: $('#tournament').offset().top }, 1000);
        };

        fetchTournaments();
    }, [gameId, accessToken, currentPage, selectedFilter]);

    const handleFilterChange = (filter) => {
        console.log('This is my filter code');
        setSelectedFilter(filter);
        setActiveFilter(filter);
        setCurrentPage(1); // Reset to page 1 when the filter changes
    };

    if (isLoading) {
        return <div>Loading.....</div>;
    }

    if (error) {
        console.log('error handling', error);
        return <div>Something went wrong! Please try again later..</div>;
    }

    // if (tournaments.length === 0 && !isLoading) {
    //   return <div>No tournaments available at the moment.</div>; // Handle empty tournaments list
    // }

    return (
        <>
            <section className="tournament-sec-v2 space-extra2-bottom" data-bg-src={TournamentSec2Bg}>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-12">
                            <div className="title-area text-center">
                                <span className="sub-title style2"># Game Streaming Battle </span>
                                <h2 className="sec-title">
                                    Our Gaming Tournaments <span className="text-theme">!</span>
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="tournament-filter-btn2 filter-menu filter-menu-active">
                                <button
                                    data-filter=".cat1"
                                    className={`tab-btn th-btn style-border3 ${
                                        activeFilter === 'upcoming' ? 'active' : ''
                                    }`}
                                    type="button"
                                    onClick={() => handleFilterChange('upcoming')}
                                >
                                    <span className="btn-border">
                                        UPCOMING <i className="fa-solid fa-arrow-right ms-2"></i>
                                    </span>
                                </button>

                                <button
                                    data-filter=".cat1"
                                    className={`tab-btn th-btn style-border3 ${
                                        activeFilter === 'live' ? 'active' : ''
                                    }`}
                                    type="button"
                                    onClick={() => handleFilterChange('live')}
                                >
                                    <span className="btn-border">
                                        LIVE <i className="fa-solid fa-arrow-right ms-2"></i>
                                    </span>
                                </button>

                                {/* <button data-filter=".cat2" 
                className={`tab-btn th-btn style-border3 ${activeFilter === 'previous' ? 'active' : ''}`}  
                type="button"  
                onClick={() => handleFilterChange('previous')}>
                  <span className="btn-border">PREVIOUS <i className="fa-solid fa-arrow-right ms-2"></i></span>
                </button> */}

                                <button
                                    style={{ display: accessToken ? 'block' : 'None' }}
                                    data-filter="*"
                                    className={`tab-btn th-btn style-border3 ${
                                        activeFilter === 'my-tournaments' ? 'active' : ''
                                    }`}
                                    type="button"
                                    onClick={() => handleFilterChange('my-tournaments')}
                                >
                                    <span className="btn-border">
                                        MY TOURNAMENTS
                                        <i className="fa-solid fa-arrow-right ms-2"></i>
                                    </span>
                                </button>

                                <button
                                    style={{ display: accessToken ? 'None' : 'block' }}
                                    data-filter="*"
                                    className={`tab-btn th-btn style-border3 ${activeFilter === 'all' ? 'active' : ''}`}
                                    type="button"
                                    onClick={() => handleFilterChange('all')}
                                >
                                    <span className="btn-border">
                                        All
                                        <i className="fa-solid fa-arrow-right ms-2"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="row gy-40 filter-active">
                        {tournaments.length === 0 ? <div>No tournaments available at the moment.</div> : <></>}
                        {tournaments.map((tournament, index) => {
                            const gamerHandle = gameHandles[tournament.game_id];
                            console.log('Found Gamer Handle', gameId, Object.keys(gameHandles), gamerHandle);
                            return (
                                <ProTournamentCard7
                                    key={tournament.tournament_id}
                                    gameId={tournament.game_id}
                                    gamerHandle={gamerHandle}
                                    tournamentId={tournament.tournament_id}
                                    // tournamentImg1={GameVS1} // Placeholder image
                                    // tournamentImg2={GameVS1} // Placeholder image
                                    tournamentImage={getImage(tournament.name, tournament.image_icon)}
                                    gameVSImg={GameVS1}
                                    cardBg={TournamentCard2Bg}
                                    subtitle1={tournament.mode}
                                    title1={tournament.name}
                                    subtitle2={tournament.duration}
                                    title2={tournament.max_prize_pool}
                                    // time={new Date(tournament.start_time * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })}
                                    endTime={tournament.end_time}
                                    startTime={tournament.start_time}
                                    // date={new Date(tournament.start_time * 1000).toLocaleDateString()}
                                    status={tournament.status}
                                    currentPlayers={tournament.current_players}
                                    maxPlayers={tournament.max_players}
                                    detailsLink={
                                        accessToken
                                            ? `/proTournament-details/${tournament.game_id}/${tournament.tournament_id}`
                                            : `/tournament-details/${tournament.game_id}/${tournament.tournament_id}`
                                    }
                                    ctaText={tournament.cta_text}
                                    ctaEnabled={tournament.cta_enabled}
                                    entryFee={tournament.entry_fee}
                                    userRegistrationAllowed={tournament.user_registration_allowed}
                                    userReistered={tournament.user_registered}
                                />
                            );
                        })}
                    </div>

                    {(tournaments.length > 9 || totalRecords > pageSize) && (
                        <div className="pt-60 text-center">
                            <div className="th-pagination pagination-btn">
                                <ul>
                                    <li>
                                        <button
                                            className="page-previous"
                                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                            disabled={currentPage === 1}
                                        >
                                            <span className="btn-border">
                                                <i className="far fa-arrow-left"></i>
                                            </span>
                                        </button>
                                    </li>
                                    <li>
                                        <span>{currentPage}</span>
                                    </li>
                                    <li>
                                        <button
                                            className="page-next"
                                            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                            disabled={currentPage === totalPages}
                                        >
                                            <span className="btn-border">
                                                <i className="far fa-arrow-right"></i>
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default ProTournamentsList2;
