import React, { useState, useEffect } from 'react';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import RealCash from '../../Assets/img/wallet/real_cash.png';

import { BASE_URL, fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import { useAppContext } from '../../Utils/Contexts/appContext';

const ProductsArea = () => {
    const { productDetails, setProductDetails, userProfile, setUserProfile } = useAppContext();
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const [redeemResponse, setRedeemResponse] = useState(null);
    const [showRedeemModal, setShowRedeemModal] = useState(false);
    const [isRedeeming, setIsRedeeming] = useState(false);

    const handleShowDetails = (reward) => {
        setSelectedProduct(reward);
        setShowModal(true);
    };
    const handleSort = (e) => {
        console.log(e.target.value);
        if (e.target.value === 'price') {
            const sortedProducts = productDetails.sort(
                (a, b) => parseFloat(a.valueDenominations) - parseFloat(b.valueDenominations),
            );
            setProductDetails([...sortedProducts]);
            setCurrentPage(1); // Reset to first page after sorting
        } else if (e.target.value === 'price-desc') {
            const sortedProducts = productDetails.sort(
                (a, b) => parseFloat(b.valueDenominations) - parseFloat(a.valueDenominations),
            );
            setProductDetails([...sortedProducts]);
            setCurrentPage(1); // Reset to first page after sorting
        } else {
            setProductDetails([...productDetails]);
            setCurrentPage(1); // Reset to first page after sorting
        }
    };

    useEffect(() => {
        if (showModal || showRedeemModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        // Cleanup function to restore scrolling when component unmounts
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [showModal, showRedeemModal]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = productDetails.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(productDetails.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    const handleRedeem = async (reward) => {
        try {
            setIsRedeeming(true);
            const response = await fetchWithAuth(`${BASE_URL}/marketplace/rewards/${reward.productId}/redeem`, {
                method: 'POST',
                headers: {},
                body: JSON.stringify({
                    reward_cost: parseFloat(reward.valueDenominations),
                }),
            });
            const data = await response.json();
            setRedeemResponse(data);
            if (data.code === 200) {
                setUserProfile({
                    ...userProfile,
                    winnings_wallet: data.newBalance,
                });
            }
            setShowRedeemModal(true);
        } catch (error) {
            setRedeemResponse({ error: error.message });
            setShowRedeemModal(true);
        } finally {
            setIsRedeeming(false);
        }
    };

    return (
        <>
            <section className="space-top space-extra2-bottom" style={{ marginTop: '30px', backgroundColor: 'black' }}>
                <div className="container">
                    <div className="th-sort-bar">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md">
                                <p className="woocommerce-result-count">
                                    Showing {indexOfFirstItem + 1}–{Math.min(indexOfLastItem, productDetails.length)} of{' '}
                                    {productDetails.length} results
                                </p>
                            </div>

                            <div className="col-md-auto">
                                <form className="woocommerce-ordering" method="get">
                                    <select
                                        name="orderby"
                                        className="orderby"
                                        aria-label="Shop order"
                                        onChange={(e) => handleSort(e)}
                                    >
                                        <option value="menu_order" selected="selected">
                                            Default Sorting
                                        </option>
                                        <option value="price">Sort by price: low to high</option>
                                        <option value="price-desc">Sort by price: high to low</option>
                                    </select>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-40">
                        {currentItems.map((reward) => (
                            <div
                                key={reward.productId + reward.valueDenominations}
                                className="col-lg-3 col-md-6"
                                style={{ padding: 5 }}
                            >
                                <div
                                    className="product-card"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleShowDetails(reward)}
                                >
                                    <div className="product-img">
                                        <img src={reward.imageUrl} alt={reward.name} className="w-100" />
                                    </div>
                                    <div className="product-content">
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '0 8px',
                                                marginBottom: '8px',
                                            }}
                                        >
                                            <h3
                                                className="product-title"
                                                style={{
                                                    margin: 0,
                                                    fontSize: '0.9rem',
                                                }}
                                            >
                                                {reward.name}
                                            </h3>
                                            <div
                                                className="price-display"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginLeft: '8px',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                <img
                                                    src={RealCash}
                                                    alt="Real Cash"
                                                    style={{
                                                        width: '20px',
                                                        height: '16px',
                                                        marginRight: '4px',
                                                        marginBottom: '2px',
                                                    }}
                                                />
                                                {reward.valueDenominations}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                padding: '0 8px',
                                            }}
                                        >
                                            <button
                                                className="th-btn style-border3"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRedeem(reward);
                                                }}
                                                disabled={isRedeeming}
                                                style={{
                                                    padding: '4px 12px',
                                                    fontSize: '0.85rem',
                                                    width: '100%',
                                                }}
                                            >
                                                <span className="btn-border">
                                                    {isRedeeming ? (
                                                        <span
                                                            className="spinner-border spinner-border-sm text-theme"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                    ) : (
                                                        <>
                                                            REDEEM <i className="fa-solid fa-arrow-right ms-2"></i>
                                                        </>
                                                    )}
                                                </span>
                                            </button>
                                        </div>
                                        <div className="product-meta">
                                            {/* <span className="badge bg-primary">{reward.countryName}</span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="th-pagination text-center pt-50">
                        <ul>
                            {Array.from({ length: totalPages }, (_, index) => (
                                <li key={index + 1}>
                                    <button
                                        onClick={() => handlePageChange(index + 1)}
                                        className={currentPage === index + 1 ? 'active' : ''}
                                        style={{
                                            border: 'none',
                                            background: 'none',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <span className="btn-border">{index + 1}</span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>

            {showModal && (
                <div
                    className="custom-modal"
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000,
                    }}
                >
                    <div
                        className="modal-content"
                        style={{
                            width: '90%',
                            maxWidth: '1200px',
                            border: '1px solid var(--theme-color)',
                            borderRadius: '4px',
                            position: 'relative',
                            textAlign: 'start',
                        }}
                    >
                        <button
                            className="btn-close"
                            onClick={() => setShowModal(false)}
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '10px',
                                background: 'none',
                                border: 'none',
                                color: 'white',
                                fontSize: '24px',
                                cursor: 'pointer',
                                zIndex: 1001,
                            }}
                        >
                            <i className="fas fa-times"></i>
                        </button>
                        <div
                            className="modal-body"
                            style={{
                                background: 'var(--black-color3)',
                                color: 'white',
                                padding: '20px',
                            }}
                        >
                            {selectedProduct && (
                                <div className="product-details col-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="price mb-3">
                                                <strong>Price:</strong>{' '}
                                                <img
                                                    src={RealCash}
                                                    alt="Real Cash"
                                                    style={{
                                                        width: '20px',
                                                        height: '16px',
                                                        marginRight: '4px',
                                                        marginBottom: '2px',
                                                    }}
                                                />
                                                {selectedProduct.valueDenominations}
                                            </div>
                                            <div className="description mb-3">
                                                <div
                                                    style={{
                                                        maxHeight: '200px',
                                                        overflowY: 'auto',
                                                        padding: '10px',
                                                        border: '0.2px solid var(--theme-color)',
                                                        borderRadius: '4px',
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: selectedProduct.redemptionInstructions,
                                                    }}
                                                ></div>
                                            </div>
                                            <div className="categories">
                                                <strong>Instructions:</strong>{' '}
                                                <div
                                                    style={{
                                                        maxHeight: '200px',
                                                        overflowY: 'auto',
                                                        padding: '10px',
                                                        border: '1px solid var(--theme-color)',
                                                        borderRadius: '4px',
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: selectedProduct.termsAndConditionsInstructions,
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {showRedeemModal && (
                <div
                    className="custom-modal"
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000,
                    }}
                >
                    <div
                        className="modal-content"
                        style={{
                            width: '90%',
                            maxWidth: '500px',
                            background: 'var(--black-color3)',
                            color: 'white',
                            padding: '20px',
                            borderRadius: '4px',
                            position: 'relative',
                        }}
                    >
                        <button
                            className="btn-close"
                            onClick={() => setShowRedeemModal(false)}
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '10px',
                                background: 'none',
                                border: 'none',
                                color: 'white',
                                fontSize: '24px',
                                cursor: 'pointer',
                            }}
                        >
                            <i className="fas fa-times"></i>
                        </button>
                        <h3>Redemption Status</h3>
                        {redeemResponse?.error ? (
                            <div className="alert alert-danger">{redeemResponse.error}</div>
                        ) : (
                            <>
                                <div className="alert alert-success">{redeemResponse?.message}</div>
                                {redeemResponse?.redeemedRewardData?.data?.placeOrder?.data?.vouchers?.map(
                                    (voucher, index) => (
                                        <div key={index} className="voucher-details p-3 border rounded mb-2">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <strong>Voucher Code:</strong>
                                                <div className="d-flex align-items-center">
                                                    <code className="me-2">{voucher.voucherCode}</code>
                                                    <button
                                                        className="btn btn-sm btn-outline-primary"
                                                        onClick={() =>
                                                            navigator.clipboard.writeText(voucher.voucherCode)
                                                        }
                                                    >
                                                        <i className="fas fa-copy"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-2">
                                                <strong>Validity:</strong> {voucher.validity}
                                            </div>
                                        </div>
                                    ),
                                )}
                                <div className="mt-3">
                                    <strong>New Balance:</strong> {redeemResponse?.newBalance}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ProductsArea;
