export const BASE_URL = 'https://wbdljuzk0c.execute-api.ap-south-1.amazonaws.com/api';

export const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
  
    try {
      const response = await fetch(`${BASE_URL}/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: refreshToken,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to refresh token');
        // Navigate the user to login page when fails
      }
  
      const data = await response.json();
  
      // Save new access token
      localStorage.setItem('accessToken', data.accessToken);
      console.log('Access token refreshed successfully:', data.accessToken);
    } catch (error) {
      console.error('An error occurred while refreshing the access token:', error);
      // Optionally redirect to login page if refresh fails
      window.location.href = '/login';
    }
  };
  


export const fetchWithAuth = async (url, options = {}) => {
    let accessToken = localStorage.getItem('accessToken');
    
    // Set Authorization header with access token
    options.headers = {
      ...options.headers,
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };
  
    const response = await fetch(url, options);
    console.log("Response :", response);
  
    if (response.status === 401 || response.status === 422) {
        console.log("Refresh token")
      // If a 401 error occurs, try to refresh the token
      await refreshAccessToken();
  
      // Retry the request with the new access token
      accessToken = localStorage.getItem('accessToken');
      options.headers['Authorization'] = `Bearer ${accessToken}`;
  
      // Retry the original request
      return await fetch(url, options);
    }
  
    return response;
  };



  

  

// APIS

export const fetchAllTournments = async (gameId, status, pageSize, currentPage) => {
    
    const response = await fetchWithAuth(
        `${BASE_URL}/tournaments?status=${status}&page_size=${pageSize}&page=${currentPage}&gameId=${gameId}`, { 
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch tournaments: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      return data;
}

export const fetchTournmentDetails = async (tournamentId) => {
    
    const response = await fetchWithAuth(
        `${BASE_URL}/tournaments/${tournamentId}`, { 
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch tournament detail: ${tournamentId} ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      return data;
}

