import React from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import Swiper from 'swiper';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import 'swiper/css'; // Import base CSS
import 'swiper/css/navigation'; // Import navigation CSS if used
import 'swiper/css/pagination'; // Import pagination CSS if used
import { Link } from 'react-router-dom';
import FeatureCardBg from '../../Assets/img/feature/feature-card-bg.png';
import Feature1_1 from '../../Assets/img/feature/feature1-1.png';
import Feature1_2 from '../../Assets/img/feature/feature1-2.png';
import Feature1_3 from '../../Assets/img/feature/feature1-3.png';

const Card1 = ({ 
    gameId,
    featureCardBg, 
    featureImage, 
    gameTitle, 
    gameDescription, 
    iconUrl
  }) => {
    return (
      
        <div className="feature-game-card">
          <div className="feature-game-card-img">
            <Link>
              <div className="feature-card-img-shape icon-masking">
                {/* <span className="mask-icon" data-mask-src={featureCardBg}></span>
                <img src={featureCardBg} alt="background" /> */}
              </div>
              <img src={featureImage} alt="game" />
            </Link>
            <Link className="th-btn">
              PLAY NOW <i className="fa-solid fa-arrow-right ms-2"></i>
            </Link>
          </div>
          <div className="feature-game-card-details">
            <h3 className="box-title">
            {gameTitle}
            </h3>
            {/* <p className="text">{gameDescription}</p> */}
          </div>
        </div>
     
    );
  };
  
  export default Card1;
