import React, { useState } from 'react';

function PartnerForm() {
    const [items, setItems] = useState([
        { id: 1, text: 'Host a Tournament', checked: false },
        { id: 2, text: 'Sponsor any Tournament', checked: false },
        { id: 3, text: 'Game Studio/Publishers looking for engagement/feedback', checked: false },
        { id: 4, text: 'Any other colaboration. Please describe', checked: false },
    ]);

    const [isDisplayOthersTextBox, setIsDisplayOthersTextBox] = useState(false);

    const [text, setText] = useState('');

    const handleTextAreaChange = (event) => {
        setText(event.target.value);
    };

    const toggleCheck = (id) => {
        setItems((prevItems) => prevItems.map((item) => (item.id === id ? { ...item, checked: !item.checked } : item)));
        if (id == 4) {
            setIsDisplayOthersTextBox(!isDisplayOthersTextBox);
        } else {
            if (setIsDisplayOthersTextBox) {
                setIsDisplayOthersTextBox(false);
            }
        }
    };

    return (
        <div className="pop-up-text">
            <ul className="checklist">
                {items.map((item) => (
                    <li key={item.id}>
                        <label>
                            <input type="checkbox" checked={item.checked} onChange={() => toggleCheck(item.id)} />
                            <span className={`checkmark ${item.checked ? 'checked' : ''}`}></span>
                            {item.text}
                        </label>
                    </li>
                ))}
            </ul>
            {isDisplayOthersTextBox && (
                <div className="others-text-box">
                    <textarea
                        className="partner-ta"
                        value={text}
                        onChange={handleTextAreaChange}
                        placeholder="Write here..."
                    />
                </div>
            )}
            <div
                className="text-white"
                style={{ fontFamily: 'var(--title-font)', fontWeight: '500', fontSize: '18px' }}
            >
                Tell us how to reach you. We respond real quick
            </div>
            <div className="partner-contacts" style={{ display: 'flex', gap: '20px' }}>
                <div style={{ width: '50%' }}>
                    <input className="pinput" placeholder="Your Name" />
                    <i class="fa fa-user picon" aria-hidden="true"></i>
                </div>
                <div style={{ width: '50%' }}>
                    <input
                        className="pinput"
                        placeholder="Phone Number"
                        type="number"
                        maxLength="10"
                        pattern="[0-9]{10}"
                        onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
                    />
                    <i class="fa fa-mobile picon" aria-hidden="true"></i>
                </div>
            </div>
        </div>
    );
}

export default PartnerForm;
